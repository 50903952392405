import { useEffect, useState } from "react"
import { expirationHandler, formatCur, formatDate, instance, serialize, twoDigits, waitForToken } from "../../utils";
import LoadingScreen from "../../components/LoadingScreen";
import TablePage from "../../components/TablePage";
import Button from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextInput from "../../components/TextInput";
import Modal from "../../components/Modal";

const ExpenseForm = ({onSubmit}) => {
  const [name, setName] = useState("")
  const [price, setPrice] = useState("")
  const now = new Date();
  const [date, setDate] = useState(`${now.getFullYear()}-${twoDigits(now.getMonth() + 1)}-${twoDigits(now.getDate())}`)

  const submit = (e) => {
    e.preventDefault();
    onSubmit({
      amount: price,
      name,
      tanggal: date
    })
  }

  return (
    <form onSubmit={submit} id="expense-form" className="flex flex-wrap justify-center">
      <div className="w-1/2 px-2 py-1">
        <label>Name</label>
        <TextInput placeholder="Keterangan" className="w-full" value={name} onChange={(e) => setName(e.target.value)} />
      </div>
      <div className="w-1/2 px-2 py-1">
        <label>Jumlah</label>
        <TextInput placeholder="pengeluaran" className="w-full" type="number" value={price} onChange={(e) => setPrice(e.target.value)} />
      </div>
      <div className="w-1/2 px-2 py-1">
        <label>Tanggal</label>
        <input type="date" className="border w-full block border-gray-400 mb-2 px-3 py-1 rounded-lg" onChange={(e) => setDate(e.target.value)} value={date} />
      </div>
    </form>
  )
}

export default function() {
  const [rows, setRows] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const defaultOrder = { column: "id", asc: false };
  const [sortBy, setSort] = useState({ ...defaultOrder });
  const [isLoading, setIsLoading] = useState(true);
  const [dataLength, setDataLength] = useState(0);
  const [showModal, setShowModal] = useState(false);
  
  const getParams = (obj = {}) =>
    serialize({
      search: searchInput,
      page: page - 1,
      column: sortBy.column,
      direction: sortBy.asc,
      ...obj,
    });

  useEffect(() => {
    waitForToken(getExpenseStart)
  }, [])

  const getExpenseStart = () => {
    instance.get("pengeluaran" + getParams()).then(({data}) => {
      setIsLoading(false);
      setDataLength(data.length);
      setRows(data.value)
    }).catch((e) => expirationHandler(e, setIsLoading));
  }

  const addExpense = (payload) => {
    setIsLoading(true);
    instance.post("pengeluaran" + getParams(), payload).then(({data}) => {
      setIsLoading(false);
      setDataLength(data.length);
      setRows(data.value);
      setShowModal(false)
    }).catch((e) => expirationHandler(e, setIsLoading));
  }

  return(
  <div>
    <LoadingScreen show={isLoading} />
    <TablePage movePageRequest="pengeluaran"
      state={{
        searchInput: [searchInput, setSearchInput],
        page: [page, setPage],
        sortBy: [sortBy, setSort],
        dataLength,
        setRows,
        setIsLoading,
      }}
      methods={{
        getParams,
        search: getExpenseStart,
      }}
      leftHeader={
        <Button
          variant="dark"
          onClick={() => setShowModal(true)}
        >
          <FontAwesomeIcon icon="plus-circle" className="mr-2" />
          Tambah data
        </Button>
      }
      columns={{
        header: [
          {label: "Pengeluaran", column: "name"},
          {label: "Jumlah", column: "amount"},
          {label: "Tanggal", column: "tanggal"}
        ],
        defaultOrder,
        row: rows.map((x) => {
          const columns = [
            x.name,
            formatCur(x.amount),
            formatDate(x.tanggal)
          ]
          return (
            <tr
              key={x.id}
              className="border-slate-400 last:border-b-0 border-b border-t transition hover:bg-[#a7b9cd] odd:bg-[#bdcada] "
            >
              {columns.map((val, idx) => (
                <td
                  key={idx}
                  className="items-center font-medium pb-2 text-center pt-2"
                >
                  {val}
                </td>
              ))}
            </tr>
          );
        })
      }}
      />
      {showModal && 
        <Modal
          close={() => {
            setShowModal(false);
          }}
          title="Tambah Pengeluaran"
          body={
            <ExpenseForm onSubmit={addExpense} />
          }
          footer={
            <div className="w-full flex justify-between">
              <Button variant="light" onClick={() => {
                setShowModal(false);
              }}>
                Cancel
              </Button>
              <Button variant="dark" type="submit" form="expense-form">
                Send
              </Button>
            </div>
          }
        />
          }
  </div>
  )
}