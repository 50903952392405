export default function Checkbox({ value = null, onChange = () => {}, className = "" }) {
  return (
    <input
      className={
        "form-check-input appearance-none h-5 w-5 border border-slate-400 rounded-md bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 my-1 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer " + className
      }
      onChange={onChange}
      type="checkbox"
      checked={value}
    />
  );
}
