import { useEffect, useRef, useState } from "react";
import Button from "../../components/Button";
import { expirationHandler, instance, waitForToken } from "../../utils";
import { io } from "socket.io-client";
import Whatsapp from "./Whatsapp";
import LoadingScreen from "../../components/LoadingScreen";
import Mikrotik from "./Mikrotik";

export default function Settings() {
  const [whatsapp, setWhatsapp] = useState({
    status: "",
    messages: {
      warn: "",
      perpanjang: "",
      send: "",
      installasi: ""
    },
    time: "",
    daysBefore: 0,
  });
  const [mikrotik, setMikrotik] = useState({
    host: "",
    user: "",
    pass: "",
    pool: "",
  });
  const [radius, setRadius] = useState({
    host: "",
    username: "",
    password: "",
  });
  const [pools, setPools] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const whatsappRef = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    waitForToken(() => {
      instance
        .get("settings")
        .then(({ data }) => {
          setIsLoading(false);
          setWhatsapp(data.whatsapp);
          setMikrotik(data.connection.mikrotik);
          setRadius(data.connection.radius);
          setPools(data.connection.pools);

          if (data.whatsapp.status === "open") {
            whatsappRef.current.openConnection();
          }
        })
        .catch((e) => {
          expirationHandler(e, setIsLoading);
        });
    });
  }, []);

  return (
    <div className="h-screen scrollbar overflow-auto pb-20">
      <LoadingScreen show={isLoading} />
      <Whatsapp
        whatsapp={[
          whatsapp,
          (attr, val) => setWhatsapp({ ...whatsapp, [attr]: val }),
        ]}
        setIsLoading={setIsLoading}
        ref={whatsappRef}
      />
      <Mikrotik
        setIsLoading={setIsLoading}
        connection={[
          [
            mikrotik,
            (attr, newVal) =>
              setMikrotik({
                ...mikrotik,
                [attr]: newVal,
              }),
          ],
          [
            radius,
            (attr, newVal) =>
              setRadius({
                ...radius,
                [attr]: newVal,
              }),
          ],
          pools,
        ]}
      />
    </div>
  );
}
