import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import Backdrop from "./Backdrop";
import Button from "./Button";

export default function Modal({ close, title, body, footer, size = "nm" }) {
  const width = () => {
    switch (size) {
      case "xl":
        return 700;
      case "lg":
        return 600;
      case "nm":
        return 500;
      case "sm":
        return 400;
      case "xs":
        return 250;
    }
  };

  return (
    <Backdrop onClick={close} className="z-[3]">
      <motion.div
        className="bg-slate-100 rounded-lg px-4"
        style={{ width: width() }}
        exit={{ y: "100vh" }}
        initial={{ y: "100vh" }}
        animate={{
          y: "0",
          transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
          },
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="flex justify-between text-xl pl-2 pt-3 pb-2 border-b border-slate-400 font-semibold">
          <div>{title}</div>
          <button
            onClick={close}
            className="rounded-xl px-2 transition focus:outline outline-offset-2 outline-1"
          >
            <FontAwesomeIcon style={{ height: 19 }} icon="xmark" />
          </button>
        </div>
        <div className="py-5 border-b border-slate-400">{body}</div>
        <div className="border-b border-slate-400 py-4">
          {footer ? footer : <Button onClick={close}>Close</Button>}
        </div>
      </motion.div>
    </Backdrop>
  );
}
