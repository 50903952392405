import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, useRef, useState } from "react";

const PasswordInput = forwardRef(
  (
    { value, onChange, className, placeholder, type, minLength, required },
    ref
  ) => {
    const [show, setShow] = useState(false);
    return (
      <div className="relative">
        <input
          type={show ? "text" : type}
          minLength={minLength}
          className={
            "border border-gray-400 px-3 py-1 pr-12 rounded-lg " + className
          }
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          required={required}
          ref={ref}
        />
        <div
          className="absolute top-1/2 translate-y-[-50%] right-3 bg-slate-200 px-1 rounded-lg pointer"
          onClick={() => setShow(!show)}
        >
          <FontAwesomeIcon
            icon={show ? "eye" : "eye-slash"}
            height={5}
            width={17}
          />
        </div>
      </div>
    );
  }
);

const NumberInput = forwardRef(
  (
    { value, onChange, className, placeholder, minLength, required, step },
    ref
  ) => {
    const toCommas = (num) =>
      num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    const [number, setNumber] = useState(toCommas(value));
    const stepAmount = step ? step : 1;

    const changeValue = (e) => {
      const { value } = e.target;
      const newVal = Number(value.replaceAll(".", ""));
      if (isNaN(newVal)) return;
      const newEvent = { ...e };
      newEvent.target.value = newVal;
      setNumber(toCommas(newVal));
      onChange(newEvent);
    };

    return (
      <input
        type="text"
        minLength={minLength}
        className={"border border-gray-400 px-3 py-1 rounded-lg " + className}
        onChange={changeValue}
        onKeyDown={(e) => {
          if (e.key === "ArrowUp") {
            e.preventDefault();
            const event = {
              target: { value: Number(value) + stepAmount + "" },
            };
            changeValue(event);
          } else if (e.key === "ArrowDown") {
            e.preventDefault();
            const nextValue = Number(value) - stepAmount;
            const event = { target: { value: "" } };
            if (nextValue > 0) {
              event.target.value = nextValue + "";
            } else {
              event.target.value = "0";
            }
            changeValue(event);
          }
        }}
        value={number}
        placeholder={placeholder}
        required={required}
        ref={ref}
      />
    );
  }
);

const TextInput = forwardRef(
  (
    {
      value = "",
      onChange = () => {},
      className = "",
      placeholder = "",
      type = "text",
      minLength = 0,
      required = false,
      step = 1,
      onFocus = () => {},
      onBlur = () => {},
      pattern = null,
      autoFocus = false,
    },
    ref
  ) => {
    const localRef = useRef(null);
    const inputRef = ref || localRef;

    switch (type) {
      case "password":
        return (
          <PasswordInput
            type={type}
            minLength={minLength}
            className={className}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            required={required}
            onFocus={onFocus}
            onBlur={onBlur}
            pattern={pattern}
            ref={inputRef}
          />
        );
      case "number-comma":
        return (
          <NumberInput
            minLength={minLength}
            className={className}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            required={required}
            step={step}
            onFocus={onFocus}
            onBlur={onBlur}
            pattern={pattern}
            ref={inputRef}
          />
        );
      default:
        return (
          <input
            type={type}
            minLength={minLength}
            className={
              "border border-gray-400 px-3 py-1 rounded-lg " + className
            }
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            required={required}
            onFocus={onFocus}
            onBlur={onBlur}
            pattern={pattern}
            ref={inputRef}
            autoFocus={autoFocus}
          />
        );
    }
  }
);

export default TextInput;
