import { motion } from "framer-motion";

export default function Backdrop({
  children,
  onClick = () => {},
  className = "",
}) {
  return (
    <motion.div
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      transition={{ duration: 0.09 }}
      className={
        "fixed h-screen w-screen bg-[#284a836b] left-0 top-0 z-50 flex justify-center items-center " +
        className
      }
      onClick={onClick}
    >
      {children}
    </motion.div>
  );
}
