import SideNav from "./components/SideNav";
import { Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { parseJwt, setToken } from "./utils";

export default function App() {
  const path = useLocation().pathname;
  const hideNav = () => {
    switch (path.split("/")[1]) {
      case "":
      case "setup":
      case "print":
        return true;
    }
  };
  const isMobile = useSelector((state) => state.isMobile);
  const [device, setDevice] = useState(isMobile);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const checkDevice = () => {
      const isMobile1 = 640 >= window.innerWidth;
      if (isMobile1 !== device) {
        dispatch({ type: "SET_DEVICE", payload: isMobile1 });
        setDevice(isMobile1);
      }
    };
    window.addEventListener("resize", checkDevice);
    checkDevice();
    const token = sessionStorage.getItem("token");
    if (token) {
      const tokenData = parseJwt(token);
      setToken(token, tokenData.role);
      dispatch({ type: "SET_USER", payload: tokenData });
    }
  }, []);

  return (
    <div className="flex">
      {hideNav() ? "" : <SideNav />}
      <div
        className={
          "bg-gray-200 h-screen w-full min-w-fit sm:mt-0 sm:min-w-[951px]" +
          (hideNav() ? "" : " sm:ml-[15rem] mt-10")
        }
      >
        <Outlet />
      </div>
    </div>
  );
}
