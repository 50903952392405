export default function FileInput({
  value = null,
  text = "Choose\xa0File",
  onChange = () => {},
  className = "",
  accept = "*",
}) {
  return (
    <div className="w-full mt-4 relative">
      <label htmlFor="fileInput">
        <div className="border cursor-pointer border-slate-300 rounded-lg flex">
          <div className="border-gray-700 bg-gray-700 py-1 px-4 text-white rounded-l-lg text-sm font-semibold w-[123px]">
            {text}
          </div>
          <div
            className={
              "py-1 px-2 rounded-l-lg text-sm text-ellipsis overflow-hidden whitespace-nowrap " +
              (value ? "font-semibold" : "text-gray-400")
            }
          >
            {value ? value.name : "No file chosen"}
          </div>
        </div>
      </label>
      <input
        required
        id="fileInput"
        accept={accept}
        type="file"
        className={"absolute left-0 top-0 z-[-1] hidden " + className}
        onChange={onChange}
      />
    </div>
  );
}
