import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import Button from "../../components/Button";
import Dropdown from "../../components/Dropdown";
import LoadingScreen from "../../components/LoadingScreen";
import Modal from "../../components/Modal";
import TablePage from "../../components/TablePage";
import {
  expirationHandler,
  instance,
  serialize,
  waitForToken,
} from "../../utils";
import DaerahFormBody from "./DaerahFormBody";
import DaerahRow from "./DaerahRow";
import DataChange from "./DataChange";

export default function Daerah() {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [dataLength, setDataLength] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [modalType, setModalType] = useState(false);
  const defaultOrder = { column: "id", asc: true };
  const emptyForm = { id: "", nama: "" };
  const [daerahForm, setDaerahForm] = useState({ ...emptyForm });
  const [checkboxes, setCheckboxes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortBy, setSort] = useState({ ...defaultOrder });
  const [deleteChoice, setDeleteChoice] = useState(true);
  const getParams = (obj = {}) =>
    serialize({
      search: searchInput,
      page: page - 1,
      column: sortBy.column,
      direction: sortBy.asc,
      ...obj,
    });

  const setResult = ({ value, length }) => {
    setCheckboxes(new Array(value.length).fill(false));
    setRows(value);
    setDataLength(length);
  };

  useEffect(() => {
    waitForToken(getDaerahStart);
  }, []);

  const getDaerahStart = (search) => {
    setIsLoading(true);
    const param = {}
    if (search) {
      param.page = 0
      setPage(1)
    }
    instance
      .get("daerah" + getParams(param))
      .then(({ data }) => {
        setIsLoading(false);
        setResult(data);
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  const askDelete = () => {
    setModalType(false);
    Swal.fire({
      title: "Hapus Daerah?",
      text:
        'Apakah anda yakin ingin menghapus daerah "' + daerahForm.nama + '" ?',
      icon: "question",
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) return setModalType("deleteDaerah");
      setDaerahForm({ ...emptyForm });
    });
  };

  const daerahBulkDelete = (selected) => {
    Swal.fire({
      title: "Hapus Daerah?",
      text:
        "Apakah anda yakin ingin menghapus " + selected.length + " daerah ?",
      icon: "question",
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      if (!isConfirmed) return;
      setIsLoading(true);
      const customParams = { ids: selected.map((x) => rows[x].id) };
      const noData =
        checkboxes.every(Boolean) && page === dataLength && page !== 1;
      if (noData) {
        customParams.page = page - 2;
      }
      instance
        .delete("bulk-daerah" + getParams(customParams))
        .then(({ data }) => {
          setIsLoading(false);
          setResult(data);
          if (noData) {
            setPage(page - 1);
          }
          Swal.fire({
            icon: "success",
            text: "Berhasil menghapus daerah",
          });
        })
        .catch((e) => expirationHandler(e, setIsLoading));
    });
  };

  const addDaerah = (e) => {
    e.preventDefault();
    setIsLoading(true);
    instance
      .post("daerah" + getParams({ nama: daerahForm.nama }))
      .then(({ data }) => {
        setIsLoading(false);
        setResult(data);
        closeForm();
        Swal.fire({
          title: "Berhasil Menambahkan Daerah!",
          icon: "success",
        });
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  const editDaerahConfirm = (e) => {
    e.preventDefault();
    setIsLoading(true);
    instance
      .put("daerah/" + daerahForm.id + serialize({ nama: daerahForm.nama }))
      .then(({ data }) => {
        setIsLoading(false);
        setRows(rows.map((row) => (row.id === data.id ? data : row)));
        closeForm();
        Swal.fire({
          title: "Berhasil Mengubah Daerah!",
          icon: "success",
        });
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  const deleteDaerah = () => {
    setIsLoading(true);
    const customParams = { delete: daerahForm.id };
    if (deleteChoice) {
      if (!daerahForm.nama) return setIsLoading(false);
      customParams.change = daerahForm.nama;
    }
    const noData = rows.length < 2 && page !== 1;
    if (noData) {
      customParams.page = page - 2;
    }
    setIsLoading(true);
    instance
      .delete(
        (deleteChoice ? "daerah-change" : "daerah-delete") +
          getParams(customParams)
      )
      .then(({ data }) => {
        setIsLoading(false);
        setModalType(false);
        setResult(data);
        if (noData) {
          setPage(page - 1);
        }
        setDaerahForm({ ...emptyForm });
        Swal.fire({
          title: "Berhasil Menghapus Daerah!",
          icon: "success",
        });
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  const setRowToForm = (data) => {
    setDaerahForm({
      id: data.id,
      nama: data.name,
    });
  };

  const closeForm = () => {
    setModalType(false);
    setDaerahForm({ ...emptyForm });
  };

  const showModal = () => {
    switch (modalType) {
      case "daerahForm":
        return (
          <Modal
            close={closeForm}
            title={(daerahForm.id ? "Edit" : "Tambah") + " Daerah"}
            size="xs"
            body={
              <DaerahFormBody
                modalForm={[
                  daerahForm.nama,
                  (x) => setDaerahForm({ ...daerahForm, nama: x }),
                ]}
                onSubmit={daerahForm.id ? editDaerahConfirm : addDaerah}
              />
            }
            footer={
              <div className="w-full flex justify-between">
                <Button variant="light" onClick={closeForm}>
                  Cancel
                </Button>
                <Button variant="dark" type="submit" form="daerahForm">
                  {daerahForm.id ? "Save" : "Add"}
                </Button>
              </div>
            }
          />
        );
      case "rowDetail":
        return (
          <Modal
            title={daerahForm.nama}
            close={closeForm}
            body={
              <div className="flex">
                <div className="w-full px-1">
                  <Button
                    className="w-full"
                    onClick={() => setModalType("daerahForm")}
                  >
                    <FontAwesomeIcon icon="pen-to-square" className="w-full" />
                    Edit
                  </Button>
                </div>
                <div className="w-full px-1">
                  <Button
                    className="w-full"
                    onClick={() => askDelete(daerahForm)}
                  >
                    <FontAwesomeIcon icon="trash" className="w-full" />
                    Delete
                  </Button>
                </div>
              </div>
            }
          />
        );
      case "deleteDaerah":
        return (
          <Modal
            close={closeForm}
            title={"Hapus Daerah " + daerahForm.nama}
            body={
              <div>
                <div className="flex">
                  <div className="w-full pr-1">
                    <Button
                      className="w-full"
                      onClick={() => setDeleteChoice(true)}
                      variant={deleteChoice ? "dark" : "light"}
                    >
                      Ganti daerah
                    </Button>
                  </div>
                  <div className="w-full pl-1">
                    <Button
                      className="w-full"
                      onClick={() => {
                        setDeleteChoice(false);
                      }}
                      variant={!deleteChoice ? "dark" : "light"}
                    >
                      Hapus pelanggan
                    </Button>
                  </div>
                </div>
                {deleteChoice ? (
                  <DataChange
                    changeState={[
                      daerahForm.nama,
                      (x) => setDaerahForm({ ...daerahForm, nama: x }),
                    ]}
                    isPaket={false}
                    deletePaket={() => {}}
                    deleteDaerah={deleteDaerah}
                    remove={daerahForm.id}
                  />
                ) : undefined}
              </div>
            }
            footer={
              <div className="w-full flex justify-between">
                <Button variant="light" onClick={closeForm}>
                  Cancel
                </Button>
                <Button
                  variant="dark"
                  type="submit"
                  onClick={deleteDaerah}
                  form="deleteForm"
                >
                  Confirm
                </Button>
              </div>
            }
          />
        );
    }
  };

  const checkSelected = () => {
    const res = [];
    checkboxes.forEach((x, idx) => {
      if (x) res.push(idx);
    });
    return res;
  };
  const selected = checkSelected();
  const isSelected = selected.length > 0;

  return (
    <div>
      <LoadingScreen show={isLoading} />
      <TablePage
        movePageRequest="daerah"
        state={{
          searchInput: [searchInput, setSearchInput],
          page: [page, setPage],
          sortBy: [sortBy, setSort],
          dataLength,
          setRows,
          setIsLoading,
        }}
        methods={{
          getParams,
          search: getDaerahStart,
        }}
        leftHeader={
          <Dropdown
            variant="dark"
            title="Manage Daerah"
            className="w-full flex justify-between"
            containerClass="w-full"
          >
            <div className="absolute pt-2 w-full z-[1]">
              <div className="w-full text-white rounded-lg border-gray-500 border bg-slate-700">
                <div
                  onClick={() => setModalType("daerahForm")}
                  className="py-1 px-4 cursor-pointer rounded-t-lg hover:bg-[#415166] transition "
                >
                  <FontAwesomeIcon icon="plus-circle" className="mr-3 w-4" />
                  Tambah Daerah
                </div>
                <div className="text-sm pl-3 border-b mt-2 border-gray-400">
                  Bulk
                </div>
                <div
                  className={
                    "py-1 px-4 border-gray-500 transition " +
                    (isSelected
                      ? "hover:bg-[#415166] cursor-pointer"
                      : "text-gray-400 cursor-default")
                  }
                  onClick={
                    isSelected ? () => daerahBulkDelete(selected) : () => {}
                  }
                >
                  <FontAwesomeIcon icon="minus-circle" className="mr-3 w-4" />
                  Hapus Daerah
                </div>
              </div>
            </div>
          </Dropdown>
        }
        columns={{
          header: [{ label: "Nama", column: "name" }],
          defaultOrder,
          row: rows.map((row, idx) => (
            <DaerahRow
              key={row.id}
              data={row}
              checkboxState={[
                checkboxes[idx],
                (val) =>
                  setCheckboxes(
                    checkboxes.map((x, i) => (i === idx ? val : x))
                  ),
              ]}
              methods={{
                setModalType,
                setRowToForm,
              }}
              selected={selected.length > 0}
            />
          )),
        }}
      />
      <AnimatePresence>{showModal()}</AnimatePresence>
    </div>
  );
}
