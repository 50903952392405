export default function Button({
  className = "",
  style = {},
  children = "",
  disabled = false,
  variant = "",
  onClick = () => {},
  onMouseOut = () => {},
  type = "button",
  form = false,
  title = null,
}) {
  const base = "rounded-md border transition py-1 px-2 ";

  return (
    <button
      disabled={disabled}
      onMouseOut={onMouseOut}
      className={
        base +
        (variant === "dark"
          ? "border-gray-700 bg-gray-700 text-white " +
            (disabled ? "" : "hover:bg-gray-600 hover:border-gray-600 ")
          : "border-gray-400 bg-white " +
            (disabled ? "" : "hover:bg-gray-100 ")) +
        (disabled ? "opacity-60 cursor-default " : "") +
        className
      }
      style={style}
      onClick={onClick}
      type={type}
      form={form ? form : undefined}
      title={title}
    >
      {children}
    </button>
  );
}
