import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import Button from "../../components/Button";
import Dropdown from "../../components/Dropdown";
import LoadingScreen from "../../components/LoadingScreen";
import Modal from "../../components/Modal";
import TablePage from "../../components/TablePage";
import {
  expirationHandler,
  instance,
  serialize,
  waitForToken,
} from "../../utils";
import DataChange from "./DataChange";
import PaketFormBody from "./PaketFormBody";
import PaketRow from "./PaketRow";
import Checkbox from "../../components/Checkbox";

export default function Paket() {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [dataLength, setDataLength] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [modalType, setModalType] = useState(false);
  const emptyForm = {
    id: "",
    nama: "",
    bandwidth: "",
    harga: "",
  };
  const defaultOrder = { column: "id", asc: true };
  const [paketForm, setPaketForm] = useState({ ...emptyForm });
  const [isLoading, setIsLoading] = useState(true);
  const [deleteChoice, setDeleteChoice] = useState(true);
  const [sortBy, setSort] = useState({ ...defaultOrder });
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.isMobile);
  const [checkboxes, setCheckboxes] = [
    useSelector((state) => state.checkbox),
    (newVal) => dispatch({ type: "SET_CHECKBOX", payload: newVal }),
  ];
  const getParams = (obj = {}) =>
    serialize({
      search: searchInput,
      page: page - 1,
      column: sortBy.column,
      direction: sortBy.asc,
      ...obj,
    });

  const setResult = ({ value, length }) => {
    setCheckboxes(new Array(value.length).fill(false));
    setRows(value);
    setDataLength(length);
  };

  useEffect(() => {
    waitForToken(getPaketStart);
  }, []);

  const deletePaket = (e) => {
    setIsLoading(true);
    const customParams = { delete: paketForm.id };
    if (deleteChoice) {
      e.preventDefault();
      customParams.change = paketForm.bandwidth;
    }
    const noData = rows.length < 2 && page !== 1;
    if (noData) {
      customParams.page = page - 2;
    }
    instance
      .delete(
        (deleteChoice ? "paket-change" : "paket-delete") +
          getParams(customParams)
      )
      .then(({ data }) => {
        setIsLoading(false);
        setModalType(false);
        if (noData) {
          setPage(page - 1);
        }
        setResult(data);
        setPaketForm({ ...emptyForm });
        Swal.fire({
          title: "Berhasil Menghapus Paket!",
          icon: "success",
        });
        setDeleteChoice(true);
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  const getPaketStart = (search) => {
    setIsLoading(true);
    const param = {}
    if (search) {
      param.page = 0
      setPage(1)
    }
    instance
      .get("paket" + getParams(param))
      .then(({ data }) => {
        setIsLoading(false);
        setResult(data);
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  const confirmEdit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    instance
      .put("paket/" + paketForm.id, getParams())
      .then(({ data }) => {
        setIsLoading(false);
        setRows(rows.map((row) => (row.id === data.id ? data : row)));
        setModalType(false);
        setPaketForm({ ...emptyForm });
        Swal.fire({
          title: "Berhasil Mengubah Paket!",
          icon: "success",
        });
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  const addPaket = (e) => {
    e.preventDefault();
    setIsLoading(true);
    instance
      .post("paket" + getParams(), paketForm)
      .then(({ data }) => {
        setIsLoading(false);
        setModalType(false);
        setResult(data);
        setPaketForm({ ...emptyForm });
        Swal.fire({
          title: "Berhasil Menambahkan Paket!",
          icon: "success",
        });
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  const setRowToForm = (paket) => {
    setPaketForm({
      id: paket.id,
      nama: paket.nama,
      harga: paket.harga,
      bandwidth: paket.bandwidth,
    });
  };

  const askDelete = (paket) => {
    setPaketForm({ ...paketForm, id: paket.id, nama: paket.nama });
    setModalType(false);
    Swal.fire({
      title: "Hapus Paket?",
      text: 'Apakah anda yakin ingin menghapus paket "' + paket.nama + '" ?',
      icon: "question",
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) return setModalType("deletePaket");
      setPaketForm({ ...emptyForm });
    });
  };

  const paketBulkDelete = (selected) => {
    Swal.fire({
      title: "Hapus Paket?",
      text: "Apakah anda yakin ingin menghapus " + selected.length + " paket ?",
      icon: "question",
      showCancelButton: true,
    }).then(({ isConfirmed }) => {
      if (!isConfirmed) return;
      setIsLoading(true);
      const customParams = { ids: selected.map((x) => rows[x].id) };
      const noData =
        checkboxes.every(Boolean) && page === dataLength && page !== 1;
      if (noData) {
        customParams.page = page - 2;
      }
      instance
        .delete("bulk-paket" + getParams(customParams))
        .then(({ data }) => {
          setIsLoading(false);
          setResult(data);
          if (noData) {
            setPage(page - 1);
          }
          Swal.fire({
            icon: "success",
            text: "Berhasil menghapus paket",
          });
        })
        .catch((e) => expirationHandler(e, setIsLoading));
    });
  };

  const checkSelected = () => {
    const res = [];
    checkboxes.forEach((x, idx) => {
      if (x) res.push(idx);
    });
    return res;
  };
  const selected = checkSelected();
  const isSelected = selected.length > 0;

  const showModal = () => {
    let closeForm;
    switch (modalType) {
      case "paketForm":
        closeForm = () => {
          setModalType(false);
          setPaketForm({ ...emptyForm });
        };
        return (
          <Modal
            close={closeForm}
            title={(paketForm.id ? "Edit" : "Tambah") + " Paket"}
            body={
              <PaketFormBody
                onSubmit={paketForm.id ? confirmEdit : addPaket}
                modalForm={[paketForm, setPaketForm]}
              />
            }
            footer={
              <div className="w-full flex justify-between">
                <Button variant="light" onClick={closeForm}>
                  Close
                </Button>
                <Button variant="dark" type="submit" form="paketForm">
                  {paketForm.id ? "Save" : "Add"}
                </Button>
              </div>
            }
          />
        );
      case "deletePaket":
        closeForm = () => {
          setModalType(false);
          setDeleteChoice(true);
          setPaketForm({ ...emptyForm });
        };
        return (
          <Modal
            close={closeForm}
            title={"Hapus Paket " + paketForm.nama}
            body={
              <div>
                <div className="flex">
                  <div className="w-full pr-1">
                    <Button
                      className="w-full"
                      onClick={() => setDeleteChoice(true)}
                      variant={deleteChoice ? "dark" : "light"}
                    >
                      Ganti paket
                    </Button>
                  </div>
                  <div className="w-full pl-1">
                    <Button
                      className="w-full"
                      onClick={() => {
                        setDeleteChoice(false);
                      }}
                      variant={!deleteChoice ? "dark" : "light"}
                    >
                      Hapus pelanggan
                    </Button>
                  </div>
                </div>
                {deleteChoice ? (
                  <DataChange
                    changeState={[
                      paketForm.bandwidth,
                      (x) => setPaketForm({ ...paketForm, bandwidth: x }),
                    ]}
                    isPaket={true}
                    deletePaket={deletePaket}
                    remove={paketForm.id}
                  />
                ) : undefined}
              </div>
            }
            footer={
              <div className="w-full flex justify-between">
                <Button variant="light" onClick={closeForm}>
                  Cancel
                </Button>
                <Button
                  variant="dark"
                  type="submit"
                  onClick={deletePaket}
                  form="deleteForm"
                >
                  Confirm
                </Button>
              </div>
            }
          />
        );
      case "rowDetail":
        return (
          <Modal
            title={paketForm.nama}
            close={() => {
              setModalType(false);
              setPaketForm({ ...emptyForm });
            }}
            body={
              <div className="flex">
                <div className="w-full px-1">
                  <Button
                    className="w-full"
                    onClick={() => setModalType("paketForm")}
                  >
                    <FontAwesomeIcon icon="pen-to-square" className="w-full" />
                    Edit
                  </Button>
                </div>
                <div className="w-full px-1">
                  <Button
                    className="w-full"
                    onClick={() => askDelete(paketForm)}
                  >
                    <FontAwesomeIcon icon="trash" className="w-full" />
                    Delete
                  </Button>
                </div>
              </div>
            }
          />
        );
    }
  };

  return (
    <div>
      <LoadingScreen movePageRequest="paket" show={isLoading} />
      <TablePage
        movePageRequest="paket"
        state={{
          searchInput: [searchInput, setSearchInput],
          page: [page, setPage],
          sortBy: [sortBy, setSort],
          dataLength,
          setRows,
          setIsLoading,
        }}
        methods={{
          getParams,
          search: getPaketStart,
        }}
        leftHeader={
          <Dropdown
            variant="dark"
            title="Manage Paket"
            className="sm:w-auto w-full flex justify-between"
            containerClass="sm:w-auto w-full"
          >
            <div className="absolute pt-2 w-full z-[1]">
              <div className="w-full text-white rounded-lg border-gray-500 border bg-slate-700">
                <div
                  onClick={() => setModalType("paketForm")}
                  className="py-1 px-4 cursor-pointer rounded-t-lg hover:bg-[#415166] transition "
                >
                  <FontAwesomeIcon icon="plus-circle" className="mr-3 w-4" />
                  Tambah Paket
                </div>
                <div
                  onClick={() => setModalType("import")}
                  className="py-1 px-4 cursor-pointer rounded-t-lg hover:bg-[#415166] transition "
                >
                  <FontAwesomeIcon icon="file-import" className="mr-3 w-4" />
                  Import Paket
                </div>
                <div className="text-sm pl-3 border-b mt-2 border-gray-400">
                  Bulk
                </div>
                <div
                  className={
                    "py-1 px-4 border-gray-500 transition " +
                    (isSelected
                      ? "hover:bg-[#415166] cursor-pointer"
                      : "text-gray-400 cursor-default")
                  }
                  onClick={
                    isSelected ? () => paketBulkDelete(selected) : () => {}
                  }
                >
                  <FontAwesomeIcon icon="minus-circle" className="mr-3 w-4" />
                  Hapus Paket
                </div>
              </div>
            </div>
          </Dropdown>
        }
        columns={
          isMobile ? 
            {
              header: [
                { label: "Nama", column: "groupname" },
                { label: "Harga", column: "price" },
                { label: "Bandwidth" },
              ],
              defaultOrder,
              row: rows.map((data, idx) => (
                <PaketRow
                  methods={{ setRowToForm, setModalType }}
                  checkboxState={[
                    checkboxes[idx],
                    (val) =>
                      setCheckboxes(
                        checkboxes.map((x, i) => (idx === i ? val : x))
                      ),
                  ]}
                  key={data.id}
                  data={data}
                  selected={selected.length > 0}
                />
              )),
            }
          :
            {
              header: [
                {
                  label: (
                    <Checkbox
                      value={checkboxes.length > 0 ? checkboxes.every(Boolean) : false}
                      onChange={(e) =>
                        setCheckboxes(
                          new Array(rows.length).fill(e.target.checked)
                        )
                      }
                    />
                  ),
                  checkbox: true,
                },
                { label: "Nama", column: "groupname" },
                { label: "Harga", column: "price" },
                { label: "Bandwidth" },
                { label: "Action" },
              ],
              defaultOrder,
              row: rows.map((data, idx) => {
                const column = [
                  <Checkbox
                  value={checkboxes[idx]}
                  onChange={(e) => setCheckboxes(checkboxes.map((x, i) => (idx === i ? e.target.checked : x)))}
                  />,
                  data.nama,
                  data.harga,
                  data.bandwidth,
                  <div>
                    <Button className="mx-0.5">
                      <FontAwesomeIcon icon="pen-to-square" />
                    </Button>
                    <Button className="mx-0.5">
                      <FontAwesomeIcon icon="trash" />
                    </Button>
                  </div>
                ]
                return (
                  <tr
                    key={data.id}
                    className="border-slate-400 even:bg-slate-300 transition hover:bg-[#a7b9cd] odd:bg-[#bdcada] last:border-b-0 border-b border-t"
                  >
                    {column.map((col, i) => (
                      <td
                        key={i}
                        className={
                          "items-center font-medium pb-2 text-center pt-2" +
                          (i === 0 ? " w-[1%] whitespace-nowrap px-2" : "")
                        }
                      >
                        {col}
                      </td>
                    ))}
                  </tr>
                );
              }),
            }
        }
      />
      <AnimatePresence>{showModal()}</AnimatePresence>
    </div>
  );
}
