import { AnimatePresence, motion } from "framer-motion";
import Backdrop from "./Backdrop";
import Loading from "./Loading";

export default function LoadingScreen({ show }) {
  return (
    <AnimatePresence>
      {show && (
        <Backdrop className="z-[4]">
          <Loading />
        </Backdrop>
      )}
    </AnimatePresence>
  );
}
