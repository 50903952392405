import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { expirationHandler, instance } from "../utils";
import Paging from "./Paging";
import TextInput from "./TextInput";

export default function TablePage({
  state = {
    page: [],
    dataLength: 1,
    isLoading: false,
    searchInput: [],
    setIsLoading: () => {},
    sortBy: [],
    setRows: () => {},
  },
  leftHeader,
  smallSearch = false,
  columns = { header: [], row: false, defaultOrder: {} },
  methods = {
    search: () => {},
    getParams: () => {},
    resetCheckbox: () => {},
  },
  movePageRequest = "",
  onMovePage = () => {},
  secondHeader,
}) {
  const { dataLength, setIsLoading, setRows } = state,
    { search, getParams } = methods,
    [searchInput, setSearchInput] = state.searchInput,
    [page, setPage] = state.page,
    [sortBy, setSort] = state.sortBy,
    initialized = useRef(false),
    isMobile = useSelector((state) => state.isMobile);

  useEffect(() => {
    if (initialized.current) {
      const timeOutId = setTimeout(() => search(true), 500);
      return () => clearTimeout(timeOutId);
    } else initialized.current = true;
  }, [searchInput]);

  const movePage = (newVal) => {
    if (newVal === page) return;
    setIsLoading(true);
    let currentPage = page;
    const customParams = {};
    if (typeof newVal === "number") {
      currentPage = newVal;
    } else {
      setSort(newVal);
      customParams.column = newVal.column;
      customParams.direction = newVal.asc;
    }

    instance
      .get(movePageRequest + "/" + (currentPage - 1) + getParams(customParams))
      .then(({ data }) => {
        setIsLoading(false);
        setPage(Number(currentPage));
        setRows(data);
        onMovePage(data);
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  return (
    <div className="p-10 pr-8 h-screen scrollbar overflow-auto">
      {isMobile ? (
        <div className="w-full">
          <div className="relative mb-3 w-full">
            <FontAwesomeIcon
              icon="magnifying-glass"
              className="absolute left-3 top-2/4"
              style={{ transform: "translateY(-50%)" }}
            />
            <TextInput
              type="text"
              className={"pl-10 w-full"}
              onChange={({ target }) => setSearchInput(target.value)}
              value={searchInput}
              placeholder="search"
            />
          </div>
          {leftHeader}
        </div>
      ) : (
        <div className="flex w-full justify-between mb-4">
          {leftHeader}
          <div className="relative">
            <FontAwesomeIcon
              icon="magnifying-glass"
              className="absolute left-3 top-2/4"
              style={{ transform: "translateY(-50%)" }}
            />
            <TextInput
              type="text"
              className={"pl-10" + (smallSearch ? " w-36" : "")}
              onChange={({ target }) => setSearchInput(target.value)}
              value={searchInput}
              placeholder="search"
            />
          </div>
        </div>
      )}
      {secondHeader}
      <div className="rounded-t-3xl border-slate-400 border bg-slate-300 overflow-hidden mt-14">
        <table className="border-collapse table-auto w-full sm:text-sm text-xs">
          <thead>
            <tr>
              {columns.header.map((x, idx) => (
                <th
                  key={idx}
                  className={
                    "font-medium pb-3 text-center pt-6" +
                    (x.column
                      ? " transition hover:bg-[#a7b9cd] cursor-pointer"
                      : x.checkbox
                      ? " w-[1%] whitespace-nowrap px-2"
                      : "")
                  }
                  onClick={() => {
                    if (!x.column) return;
                    const onColumn = sortBy.column === x.column;
                    if (!onColumn || sortBy.onDefault) {
                      const newVal = { column: x.column, asc: true };
                      movePage(newVal);
                    } else if (onColumn && sortBy.asc) {
                      const newVal = { column: x.column, asc: false };
                      movePage(newVal);
                    } else if (onColumn && !sortBy.asc) {
                      const newVal = { ...columns.defaultOrder };
                      movePage(newVal);
                    }
                  }}
                >
                  {x.label}
                  {sortBy.column === x.column && !sortBy.onDefault ? (
                    <span className="mt-2 ml-2">
                      <FontAwesomeIcon
                        icon="caret-down"
                        style={{
                          transform: `rotate(${sortBy.asc ? 0 : 180}deg)`,
                        }}
                      />
                    </span>
                  ) : undefined}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{columns.row}</tbody>
        </table>
      </div>
      <Paging page={page} movePage={movePage} dataLength={dataLength} />
    </div>
  );
}
