import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TextInput from "../../components/TextInput";
import Select from "../../components/Select";
import Checkbox from "../../components/Checkbox";
import {
  expirationHandler,
  instance,
  parseJwt,
  twoDigits,
  waitForToken,
} from "../../utils";
import { useSelector } from "react-redux";
import Button from "../../components/Button";
import Swal from "sweetalert2";
import LoadingScreen from "../../components/LoadingScreen";

export default function CostumerForm() {
  const location = useLocation();
  const navigate = useNavigate();
  const [userForm, setUserForm] = useState(
    location.state.userData || {
      id: "",
      username: "",
      password: "",
      paket: "",
      aktifHingga: "",
      nama: "",
      namaKtp: "",
      daerah: "",
      alamat: "",
      nomor: "",
      ktp: "",
      biaya: "",
      notify: false,
      active: false,
      lunas: false,
      daerahObj: null,
      paketObj: null,
    }
  );
  const [pakets, setPakets] = useState([]);
  const [daerahs, setDaerahs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const userData = useSelector((state) => state.user);

  const setForm = (val, attr) => setUserForm({ ...userForm, [attr]: val });
  const change = (e, attr) => setForm(e.target.value, attr);

  useEffect(() => {
    waitForToken(() => {
      instance
        .get("all-daerah-paket")
        .then(({ data }) => {
          setPakets(data.paket.map((x) => ({ value: x.id, label: x.value })));
          setDaerahs(data.daerah.map((x) => ({ value: x.id, label: x.value })));
          setIsLoading(false);
          if (parseJwt(sessionStorage.getItem("token")).role === "subadmin") {
            setTimeout(() => {
              setForm(data.daerah[0].id, "daerah");
            }, 500);
          }
          if (!userForm.id) {
            const now = new Date();
            now.setMonth(now.getMonth() + 1);
            const dates = [
              now.getFullYear(),
              twoDigits(now.getMonth() + 1),
              twoDigits(now.getDate()),
            ];
            setForm(dates.join("-"), "aktifHingga");
          }
        })
        .catch(expirationHandler);
    });
  }, []);

  const editCostumer = (e) => {
    e.preventDefault();
    const payload = {
      alamat: userForm.alamat,
      nama: userForm.nama,
      namaKtp: userForm.namaKtp,
      username: userForm.username,
      ktp: userForm.ktp,
      password: userForm.password,
      aktifHingga: userForm.aktifHingga,
      nomor: userForm.nomor,
      DaerahId: userForm.daerah,
      PaketId: userForm.paket,
      notify: userForm.notify,
    };
    setIsLoading(true);
    instance
      .put("costumer/" + userForm.id, payload)
      .then(() => {
        setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Berhasil merubah data",
        }).then(() => {
          navigate("/costumer");
        });
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  const addCostumer = () => {
    const payload = {
      alamat: userForm.alamat,
      nama: userForm.nama,
      namaKtp: userForm.namaKtp,
      ktp: userForm.ktp,
      username: userForm.username,
      password: userForm.password,
      DaerahId: userForm.daerah,
      PaketId: userForm.paket,
      aktifHingga: userForm.aktifHingga,
      nomor: userForm.nomor,
      biaya: userForm.biaya,
      notify: userForm.notify,
    };
    setIsLoading(true);
    instance
      .post("costumer", payload)
      .then(({ data }) => {
        setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Berhasil menambahkan pelanggan!",
        })
          .then(() => [navigate("/costumer")])
          .catch((e) => expirationHandler(e, setIsLoading));
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  return (
    <form onSubmit={userForm.id ? editCostumer : addCostumer} className="px-4">
      <LoadingScreen show={isLoading} />
      <div className="text-xl px-2 pt-3 pb-2 font-semibold">
        Tambah Pelanggan
      </div>
      <div className="flex flex-wrap justify-center">
        <div className="w-1/2 px-2 py-1">
          <label className="text-sm font-medium">Nama</label>
          <TextInput
            onChange={(e) => change(e, "nama")}
            placeholder="Nama"
            className="w-full"
            value={userForm.nama}
            required
          />
        </div>
        <div className="w-1/2 px-2 py-1">
          <label className="text-sm font-medium">Nama Sesuai KTP</label>
          <TextInput
            value={userForm.namaKtp}
            required
            placeholder="Nama Sesuai KTP"
            onChange={(e) => change(e, "namaKtp")}
            type="text"
            className="w-full"
          />
        </div>
        <div className="w-1/2 px-2 py-1">
          <label className="text-sm font-medium">Daerah</label>
          <Select
            required
            className="w-full"
            outClass="w-full"
            options={daerahs}
            value={userForm.daerah}
            disabled={userData && userData.role === "subadmin"}
            onChange={(e) => setForm(e, "daerah")}
          />
        </div>
        <div className="w-1/2 px-2 py-1">
          <label className="text-sm font-medium">Alamat</label>
          <TextInput
            value={userForm.alamat}
            required
            onChange={(e) => change(e, "alamat")}
            className="w-full"
            placeholder="alamat"
          />
        </div>
        <div className="w-1/2 px-2 py-1">
          <label className="text-sm font-medium">Nomor</label>
          <TextInput
            value={userForm.nomor}
            required
            type="number"
            onChange={(e) => change(e, "nomor")}
            className="w-full"
            placeholder="nomor"
          />
        </div>
        <div className="w-1/2 px-2 py-1">
          <label className="text-sm font-medium">KTP</label>
          <TextInput
            value={userForm.ktp}
            type="text"
            onChange={(e) => change(e, "ktp")}
            className="w-full"
            placeholder="nomor KTP"
          />
        </div>
        <div className="w-1/2 px-2 py-1">
          <label className="text-sm font-medium">Username</label>
          <TextInput
            value={userForm.username}
            required
            placeholder="username"
            onChange={(e) => change(e, "username")}
            className="w-full"
          />
        </div>
        <div className="w-1/2 px-2 py-1">
          <label className="text-sm font-medium">Password</label>
          <TextInput
            value={userForm.password}
            required
            placeholder="password"
            onChange={(e) => change(e, "password")}
            type="password"
            className="w-full"
          />
        </div>
        <div className="w-1/2 px-2 py-1">
          <label className="text-sm font-medium">Paket</label>
          <Select
            value={userForm.paket}
            options={pakets}
            required
            className="w-full"
            outClass="w-full"
            onChange={(e) => setForm(e, "paket")}
          />
        </div>
        <div className="w-1/2 px-2 py-1">
          <label className="text-sm font-medium">Aktif Hingga</label>
          <input
            type="date"
            className="border border-gray-400 px-3 py-1 rounded-lg w-full"
            required
            onChange={(e) => change(e, "aktifHingga")}
            value={userForm.aktifHingga}
          />
        </div>
        {!userForm.id && (
          <div className="w-1/2 px-2 py-1">
            <label className="text-sm font-medium">Biaya</label>
            <input
              type="number"
              className="border border-gray-400 px-3 py-1 rounded-lg w-full"
              required
              onChange={(e) => change(e, "biaya")}
              value={userForm.biaya}
            />
          </div>
        )}
        <div className="px-2 py-1 w-1/2 flex items-center">
          <label>
            <Checkbox
              value={userForm.notify}
              onChange={({ target }) => setForm(target.checked, "notify")}
            />
            <span className="text-sm font-medium pl-3">Notify</span>
          </label>
        </div>
      </div>
      <div className="w-full flex justify-between">
        <Link to="/costumer">
          <Button variant="light">Cancel</Button>
        </Link>
        <Button variant="dark" type="submit">
          Submit
        </Button>
      </div>
    </form>
  );
}
