import React, { useEffect, useRef } from "react";
import QRCodeStyling from "qr-code-styling";

const qrCode = new QRCodeStyling({
  size: 300,
  height: 300,
  dotsOptions: {
    color: "#4267b2",
    type: "square",
  },
});

export default function QrCode({ data }) {
  const ref = useRef(null);

  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.update({
      data,
    });
  }, [data]);

  return <div ref={ref} />;
}
