import { useEffect, useState } from "react";
import {
  baseURL,
  expirationHandler,
  formatCur,
  formatDate,
  formatTimeDate,
  instance,
  serialize,
  waitForToken,
} from "../../utils";
import Button from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilterModal from "./FilterModal";
import LoadingScreen from "../../components/LoadingScreen";
import Modal from "../../components/Modal";
import { useSelector } from "react-redux";
import TablePage from "../../components/TablePage";
import { AnimatePresence } from "framer-motion";
import axios from "axios";

export default function History() {
  const defaultOrder = { column: "tanggal_bayar", asc: false, onDefault: true };
  const [sortBy, setSort] = useState({ ...defaultOrder });
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [dataLength, setDataLength] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [modalType, setModalType] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [total, setTotal] = useState("");
  const [temp, setTemp] = useState(null);
  const isMobile = useSelector((state) => state.isMobile);
  const [filter, setFilter] = useState({
    start: "",
    end: "",
    daerah: "",
    method: "",
    type: "bayar",
  });
  const filtered = filter.start;

  const getParams = (obj) => {
    const params = {
      type: filter.type,
      start: filter.start,
      end: filter.end,
      daerah: filter.daerah,
      search: searchInput,
      method: filter.method,
      column: sortBy.column,
      direction: sortBy.asc,
      page: page - 1,
      ...obj,
    };
    return serialize(params);
  };

  const findPembayaranStart = (search, params = {}) => {
    setIsLoading(true);
    if (search) {
      params.page = 0;
      setPage(1);
    }
    instance
      .get(`pembayaran${getParams(params)}`)
      .then(({ data }) => {
        console.log(data.value);
        setIsLoading(false);
        setRows(data.value);
        setDataLength(data.length);
        if (data.total) {
          setTotal(data.total);
        }
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  useEffect(() => {
    waitForToken(findPembayaranStart);
  }, []);

  const openDetail = (data) => {
    setTemp(data);
    setModalType("rowDetail");
  };

  const printData = (data) => {
    window.open(
      "/print/" +
        data.id +
        serialize({ token: sessionStorage.getItem("token") }),
      "_blank"
    );
  };

  const downloadResult = () => {
    instance
      .get(`laporan${getParams()}`, { responseType: "blob" })
      .then(({ data }) => {
        const href = URL.createObjectURL(data);

        const date = new Date();

        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          "Pembayaran-" +
            date.getFullYear() +
            date.getMonth() +
            date.getDate() +
            date.getHours() +
            date.getMinutes() +
            date.getSeconds() +
            ".xlsx"
        );
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  const showModal = () => {
    switch (modalType) {
      case "bukti":
        return (
          <Modal
            close={() => {
              setModalType(false);
              setSelectedImage("");
            }}
            title=""
            body={
              <div className="flex justify-center">
                <img
                  className="h-full max-h-[470px]"
                  src={baseURL + "guest/bukti-pembayaran/" + selectedImage}
                />
              </div>
            }
          />
        );
      case "rowDetail":
        return (
          <Modal
            title={temp.costumer}
            close={() => {
              setModalType(false);
              setTemp(null);
            }}
            body={
              <div className="flex">
                <div className="w-full px-1">
                  <Button
                    className="w-full"
                    disabled={!temp.bukti}
                    onClick={() => {
                      setModalType("bukti");
                      setSelectedImage(temp.bukti);
                    }}
                  >
                    <FontAwesomeIcon icon="file-image" className="w-full" />
                    Bukti
                  </Button>
                </div>
                <div className="w-full px-1">
                  <Button className="w-full" onClick={() => printData(temp)}>
                    <FontAwesomeIcon icon="print" className="w-full" />
                    Print
                  </Button>
                </div>
              </div>
            }
          />
        );
      case "filter":
        return (
          <Modal
            title="Filter Laporan"
            size="sm"
            close={() => setModalType(false)}
            body={
              <FilterModal
                onSubmit={(data) => {
                  setFilter(data);
                  findPembayaranStart(false, data);
                  setModalType(false);
                }}
                filterState={filter}
              />
            }
            footer={
              <div className="flex justify-between">
                <Button onClick={() => setModalType(false)}>Close</Button>
                <Button type="submit" form="filterForm">
                  Confirm
                </Button>
              </div>
            }
          />
        );
    }
  };

  const states = {
    searchInput: [searchInput, setSearchInput],
    page: [page, setPage],
    dataLength,
    setRows,
    sortBy: [sortBy, setSort],
    setIsLoading,
  };
  const methods = {
    getParams,
    search: findPembayaranStart,
  };
  const leftHeader = (
    <Button
      variant="dark"
      className="w-full sm:w-fit"
      onClick={() => setModalType("filter")}
    >
      <FontAwesomeIcon icon="sliders" /> Filter
    </Button>
  );

  return (
    <div>
      <LoadingScreen show={isLoading} />
      {isMobile ? (
        <TablePage
          movePageRequest="pembayaran"
          state={states}
          leftHeader={leftHeader}
          secondHeader={
            filtered ? (
              <div className="mt-3">
                <Button
                  variant="dark"
                  className="w-full"
                  onClick={downloadResult}
                >
                  <FontAwesomeIcon icon="download" className="mr-2" />
                  Download Result
                </Button>
                <span className="font-semibold text-sm ml-2">
                  Total: {formatCur(total)}
                </span>
              </div>
            ) : undefined
          }
          methods={methods}
          columns={{
            header: [
              { label: "Nama", column: "costumer" },
              { label: "Tanggal Bayar", column: "tanggal_bayar" },
              { label: "Jumlah", column: "amount" },
            ],
            defaultOrder,
            row: rows.map((data) => {
              const columns = [
                data.costumer,
                formatDate(data.tanggalBayar),
                formatCur(data.hargaPaket),
              ];
              return (
                <tr
                  key={data.id}
                  onClick={() => openDetail(data)}
                  className="border-slate-400 last:border-b-0 border-b border-t transition hover:bg-[#a7b9cd] odd:bg-[#bdcada] "
                >
                  {columns.map((x, idx) => (
                    <td
                      key={idx}
                      className="items-center font-medium pb-2 text-center pt-2"
                    >
                      {x}
                    </td>
                  ))}
                </tr>
              );
            }),
          }}
        />
      ) : (
        <TablePage
          movePageRequest="pembayaran"
          state={states}
          secondHeader={
            filtered ? (
              <div>
                <Button variant="dark" onClick={downloadResult}>
                  <FontAwesomeIcon icon="download" className="mr-2" />
                  Download Result
                </Button>
                <span className="font-semibold ml-6">
                  Total: {formatCur(total)}
                </span>
              </div>
            ) : undefined
          }
          leftHeader={leftHeader}
          methods={methods}
          columns={{
            header: [
              { label: "Nama", column: "costumer" },
              { label: "Nama KTP", column: "nama_ktp" },
              { label: "Jumlah", column: "amount" },
              { label: "Pembayaran", column: "method" },
              { label: "Tanggal Bayar", column: "tanggal_bayar" },
              { label: "Aktif Hingga", column: "aktif_hingga" },
              { label: "Pembayar", column: "user" },
              { label: "Action" },
            ],
            defaultOrder,
            row: rows.map((data) => {
              const columns = [
                data.costumer,
                data.namaKtp,
                formatCur(data.hargaPaket),
                data.method,
                formatTimeDate(data.tanggalBayar),
                formatDate(data.aktifHingga),
                data.pembayar,
                <div>
                  <Button
                    className="mx-0.5"
                    disabled={!data.bukti}
                    onClick={() => {
                      setModalType("bukti");
                      setSelectedImage(data.bukti);
                    }}
                  >
                    <FontAwesomeIcon icon="file-image" />
                  </Button>
                  <Button className="mx-0.5" onClick={() => printData(data)}>
                    <FontAwesomeIcon icon="print" />
                  </Button>
                </div>,
              ];
              return (
                <tr
                  key={data.id}
                  className="border-slate-400 last:border-b-0 border-b border-t transition hover:bg-[#a7b9cd] odd:bg-[#bdcada] "
                >
                  {columns.map((x, idx) => (
                    <td
                      key={idx}
                      className="items-center font-medium pb-2 text-center pt-2"
                    >
                      {x}
                    </td>
                  ))}
                </tr>
              );
            }),
          }}
        />
      )}
      <AnimatePresence>{showModal()}</AnimatePresence>
    </div>
  );
}
