import { useState } from "react";
import Modal from "./Modal";

export default function RowDetail({
  left = { title: "left", component: undefined },
  right = { title: "right", component: undefined },
  title = "",
  close,
}) {
  const [isLeft, setSide] = useState(true);

  return (
    <Modal
      title={title}
      close={close}
      body={
        <div>
          <div className="flex flex-wrap mt-[-20px] mb-2">
            <div
              className={
                "w-1/2 text-center font-medium pt-1 pb-2 border-t-2 cursor-pointer " +
                (isLeft
                  ? "bg-gradient-to-b from-slate-200 via-slate-200 to-transparent border-gray-400"
                  : "border-transparent")
              }
              onClick={() => setSide(true)}
            >
              {left.title}
            </div>
            <div
              className={
                "w-1/2 text-center font-medium pt-1 pb-2 border-t-2 cursor-pointer " +
                (!isLeft
                  ? "bg-gradient-to-b from-slate-200 via-slate-200 to-transparent border-gray-400"
                  : "border-transparent")
              }
              onClick={() => setSide(false)}
            >
              {right.title}
            </div>
          </div>
          {isLeft ? left.component : right.component}
        </div>
      }
    />
  );
}
