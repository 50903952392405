import { Navigate, useLocation } from "react-router-dom";

export default function GuestPrivateRoute({ condition, to, children }) {
  const location = useLocation();

  if (condition) {
    <Navigate to={to} state={{ from: location }} />;
  }

  return children;
}
