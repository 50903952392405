import { useLongPress } from "../../utils";

export default function ({ data, methods, selected, checkboxState }) {
  const { setModalType, setRowToForm } = methods;
  const [checkbox, setCheckbox] = checkboxState;
  const cols = [data.nama, data.harga, data.bandwidth];

  const onClick = () => {
    if (selected) return setCheckbox(!checkbox);
    setModalType("rowDetail");
    setRowToForm(data);
  };

  const onHold = () => {
    if (selected) return;
    setCheckbox(true);
    navigator.vibrate(200);
  };

  const longPressEvent = useLongPress(onHold, onClick);

  return (
    <tr
      {...longPressEvent}
      className={
        "border-slate-400 even:bg-slate-300 transition hover:bg-[#a7b9cd] odd:bg-[#bdcada] last:border-b-0 border-b border-t" +
        (selected ? (checkbox ? "" : " opacity-70") : "")
      }
    >
      {cols.map((col, i) => (
        <td key={i} className="items-center font-medium pb-2 text-center pt-2">
          {col}
        </td>
      ))}
    </tr>
  );
}
