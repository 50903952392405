import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import Costumer from "./pages/costumer/Costumer";
import Income from "./pages/keuangan/Income";
import Login from "./pages/authpage/Login";
import User from "./pages/user/User";
import Setup from "./pages/setup/Setup";
import DaerahPaket from "./pages/databases/DaerahPaket";
import PrivateRoute from "./components/PrivateRoute";
import Dashboard from "./pages/dashboard/Dashoboard";
import PrintReceipt from "./pages/print/PrintReceipt";
import Paket from "./pages/databases/Paket";
import Daerah from "./pages/databases/Daerah";
import Settings from "./pages/settings/Settings";
import GuestPrivateRoute from "./components/GuestPrivateRoute";
import { parseJwt } from "./utils";
import CostumerForm from "./pages/costumer/CostumerForm";
import Expense from "./pages/keuangan/Expense";
import Keuangan from "./pages/keuangan/Keuangan";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "/setup",
        element: (
          <GuestPrivateRoute
            condition={localStorage.getItem("ready") === "true"}
            to="/"
          >
            <Setup />
          </GuestPrivateRoute>
        ),
      },
      {
        path: "costumer",
        element: (
          <PrivateRoute>
            <Costumer />
          </PrivateRoute>
        ),
      },
      {
        path: "costumer_form",
        element: (
          <PrivateRoute>
            <CostumerForm />
          </PrivateRoute>
        ),
      },
      {
        path: "keuangan",
        element: (
          <PrivateRoute>
            <Keuangan />
          </PrivateRoute>
        ),
      },
      {
        path: "income",
        element: (
          <PrivateRoute>
            <Income />
          </PrivateRoute>
        ),
      },
      {
        path: "expense",
        element: (
          <PrivateRoute>
            <Expense />
          </PrivateRoute>
        ),
      },
      {
        path: "database/paket-daerah",
        element: (
          <PrivateRoute
            allowedRole="admin"
            device={false}
            differentDevice="/database/paket"
          >
            <DaerahPaket />
          </PrivateRoute>
        ),
      },
      {
        path: "database/paket",
        element: (
          <PrivateRoute
            allowedRole="subadmin"
            device={sessionStorage.getItem("token") ? (parseJwt(sessionStorage.getItem("token")).role !== "subadmin") : false}
            differentDevice="/database/paket-daerah"
          >
            <Paket />
          </PrivateRoute>
        ),
      },
      {
        path: "database/daerah",
        element: (
          <PrivateRoute
            allowedRole="admin"
            device={true}
            differentDevice="/database/paket-daerah"
          >
            <Daerah />
          </PrivateRoute>
        ),
      },
      {
        path: "database/user",
        element: (
          <PrivateRoute allowedRole="subadmin">
            <User />
          </PrivateRoute>
        ),
      },
      {
        path: "dashboard",
        element: (
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        ),
      },
      {
        path: "settings",
        element: (
          <PrivateRoute allowedRole="admin">
            <Settings />
          </PrivateRoute>
        ),
      },
      {
        path: "print/:id",
        element: <PrintReceipt />,
      },
    ],
  },
]);
export default router;
