import TextInput from "../../components/TextInput";

export default function DaerahFormBody({ modalForm, onSubmit }) {
  const [daerahForm, setDaerahForm] = modalForm;
  return (
    <form onSubmit={onSubmit} id="daerahForm">
      <label className="text-sm font-medium ml-2">Daerah</label>
      <TextInput
        value={daerahForm}
        className="w-full"
        onChange={(e) => setDaerahForm(e.target.value)}
        placeholder="Nama Daerah"
        required
      />
    </form>
  );
}
