import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LoadingScreen from "../../components/LoadingScreen";
import {
  expirationHandler,
  formatCur,
  formatDate,
  formatTimeDate,
  instance,
  waitForToken,
} from "../../utils";

export default function Dashboard() {
  const [rows, setRows] = useState([]);
  const [cards, setCards] = useState([
    {
      title: "Today's Incomes",
      amount: "",
      icon: "money-bill-wave",
    },
    {
      title: "Offline Users",
      amount: "",
      icon: "user-slash",
    },
    {
      title: "Active Users",
      amount: "",
      icon: "user",
    },
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = useSelector((state) => state.isMobile);

  useEffect(() => {
    waitForToken(() => {
      instance
        .get("dashboard")
        .then(({ data }) => {
          setIsLoading(false);
          const newAmounts = [
            formatCur(data.totalPembayaran),
            data.offlineCostumer,
            data.onlineCostumer,
          ];
          setCards(cards.map((x, i) => ({ ...x, amount: [newAmounts[i]] })));
          setRows(data.rows);
        })
        .catch((e) => expirationHandler(e, setIsLoading));
    });
  }, []);

  return (
    <div className="p-10 pr-8 scrollbar">
      <LoadingScreen show={isLoading} />
      {isMobile ? (
        <div>
          <div>
            {cards.map((card, i) => (
              <div key={i} className="w-full my-2 px-2">
                <div className="bg-slate-700 text-white text-2xl relative px-4 py-8 font-bold overflow-hidden rounded-xl">
                  <div>{card.title}</div>
                  <div>{card.amount}</div>
                  <FontAwesomeIcon
                    icon={card.icon}
                    className="absolute text-8xl right-[-5px] bottom-[-10px] opacity-25"
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="mt-10 rounded-t-3xl border-slate-400 border bg-slate-300 overflow-hidden">
            <table className="border-collapse table-auto w-full text-sm">
              <thead>
                <tr>
                  {["Nama", "Tanggal Bayar"].map((x, i) => (
                    <th key={i} className="font-medium pb-3 text-center pt-6">
                      {x}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows.map((data) => {
                  const columns = [
                    data.costumer,
                    formatTimeDate(data.tanggalBayar),
                  ];
                  return (
                    <tr
                      key={data.id}
                      className="border-slate-400 border-slate-400 last:border-b-0 border-b border-t"
                    >
                      {columns.map((x, idx) => (
                        <td
                          key={idx}
                          className="items-center font-medium pb-2 text-center pt-2"
                        >
                          {x}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div>
          <div className="flex">
            {cards.map((card, i) => (
              <div key={i} className="w-1/3 px-2">
                <div className="bg-slate-700 text-white text-2xl relative px-4 py-8 font-bold overflow-hidden rounded-xl">
                  <div>{card.title}</div>
                  <div>{card.amount}</div>
                  <FontAwesomeIcon
                    icon={card.icon}
                    className="absolute text-8xl right-[-5px] bottom-[-10px] opacity-25"
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="mt-10 rounded-t-3xl border-slate-400 border bg-slate-300 overflow-hidden">
            <table className="border-collapse table-auto w-full text-sm">
              <thead>
                <tr>
                  {[
                    "Nama",
                    "Paket",
                    "Tanggal Bayar",
                    "Aktif Hingga",
                    "Pembayar",
                  ].map((x) => (
                    <th key={x} className="font-medium pb-3 text-center pt-6">
                      {x}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows.map((data) => {
                  const columns = [
                    data.costumer,
                    data.paket,
                    formatTimeDate(data.tanggalBayar),
                    formatDate(data.aktifHingga),
                    data.pembayar,
                  ];
                  return (
                    <tr
                      key={data.id}
                      className="border-slate-400 border-slate-400 last:border-b-0 border-b border-t"
                    >
                      {columns.map((x, idx) => (
                        <td
                          key={idx}
                          className="items-center font-medium pb-2 text-center pt-2"
                        >
                          {x}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
