import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../../components/LoadingScreen";
import { errorHandler, instance } from "../../utils";
import FormTemplate from "./FormTemplate";

export default function Setup() {
  const [isLoading, setIsLoading] = useState(false);
  const mikrotikForm = useState({
    host: "",
    username: "",
    password: "",
  });
  const radiusForm = useState({
    name: "",
    host: "",
    secret: "",
  });
  const poolForm = useState({
    name: "",
    expirationPool: "",
  });
  const userForm = useState({
    username: "",
    password: "",
  });
  const [requiredStep, setRequiredStep] = useState([]);
  const [stepIndex, setStepIndex] = useState(0);
  const navigate = useNavigate();

  const step = requiredStep[stepIndex];

  useEffect(() => {
    instance
      .get("setup-step")
      .then(({ data }) => {
        setRequiredStep(data.step);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  const nextStep = () => {
    const nextIndex = stepIndex + 1;
    if (!requiredStep[nextIndex]) navigate("/");
    setStepIndex(nextIndex);
  };

  const connectMikrotik = () => {
    setIsLoading(true);
    const [host, port] = mikrotikForm[0].host.split(":");
    const payload = {
      host,
      port: Number(port),
      user: mikrotikForm[0].username,
      pass: mikrotikForm[0].password,
    };
    instance
      .post("connect-mikrotik", payload)
      .then(() => {
        setIsLoading(false);
        nextStep();
      })
      .catch((e) => {
        errorHandler(e);
        setIsLoading(false);
      });
  };

  const addRadius = () => {
    setIsLoading(true);
    const [host, port] = radiusForm[0].host.split(":");
    const payload = {
      host,
      port: Number(port),
      name: radiusForm[0].name,
      secret: radiusForm[0].secret,
    };
    instance
      .post("connect-radius", payload)
      .then(() => {
        setIsLoading(false);
        nextStep();
      })
      .catch((e) => {
        errorHandler(e, setIsLoading);
        setIsLoading(false);
      });
  };
  const usePool = () => {
    const mikrotik = {
      pool: poolForm[0].name,
      expPool: poolForm[0].expirationPool,
    };
    instance
      .post("use-pool", mikrotik)
      .then(() => {
        localStorage.setItem("ready", true);
        nextStep();
      })
      .catch((e) => {
        setIsLoading(false);
        errorHandler(e);
      });
  };

  const firstUser = () => {
    setIsLoading(true);
    instance
      .post("first-user", userForm[0])
      .then(() => {
        nextStep();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const checkForm = () => {
    switch (step) {
      case 0:
        return undefined;
      case 1:
        return (
          <FormTemplate
            formState={mikrotikForm}
            inputs={[
              { attribute: "host" },
              { attribute: "username" },
              { attribute: "password" },
            ]}
            onSubmit={connectMikrotik}
            id="connectionForm"
            title="Connect Mikrotik"
            confirmButton={"Connect"}
          />
        );
      case 2:
        return (
          <FormTemplate
            formState={radiusForm}
            inputs={[
              { attribute: "host" },
              { attribute: "name" },
              { attribute: "secret" },
            ]}
            onSubmit={addRadius}
            id="connectionForm"
            title="Connect Radius"
            confirmButton={"Connect"}
          />
        );
      case 3:
        return (
          <FormTemplate
            formState={poolForm}
            inputs={[{ attribute: "name" }, { attribute: "expirationPool" }]}
            onSubmit={usePool}
            id="connectionForm"
            title="Use Pool"
            confirmButton={"Confirm"}
          />
        );
      case 4:
        return (
          <FormTemplate
            formState={userForm}
            inputs={[{ attribute: "username" }, { attribute: "password" }]}
            onSubmit={firstUser}
            id="connectionForm"
            title="First User"
            confirmButton={"Add"}
          />
        );
    }
  };

  return (
    <div>
      <LoadingScreen show={isLoading} />
      {checkForm()}
    </div>
  );
}
