import { combineReducers } from "redux";
import userReducer from "./userReducer";
import deviceReducer from "./deviceReducer";
import checkboxReducer from "./checkboxReducer";

const reducers = combineReducers({
  user: userReducer,
  isMobile: deviceReducer,
  checkbox: checkboxReducer,
});

export default reducers;
