import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import Button from "./Button";

export default function Dropdown({
  items = [],
  title = "Dropdown",
  variant = "light",
  className = "",
  containerClass = "",
  icon = true,
  children,
  onClose = () => {},
  onOpen = () => {},
}) {
  const [isCollapse, setCollapse] = useState(true);
  const isLight = variant === "light";
  const timeout = useRef();

  const collapseTimer = () => {
    if (!isCollapse) {
      timeout.current = setTimeout(() => {
        setCollapse(true);
      }, 100);
    }
  };

  useEffect(() => {
    if (isCollapse) {
      onClose();
    } else {
      onOpen();
    }
  }, [isCollapse]);

  return (
    <div
      onMouseLeave={collapseTimer}
      onMouseEnter={() => !isCollapse && clearTimeout(timeout.current)}
      className={"relative " + containerClass}
    >
      <Button
        onClick={() => setCollapse(!isCollapse)}
        variant={variant}
        className={className}
      >
        <span>
          {icon ? (
            <FontAwesomeIcon icon="bars" color={isLight ? "black" : "white"} />
          ) : undefined}
          <span
            className={"ml-2 mr-6 font-medium" + (isLight ? "" : " text-white")}
          >
            {title}
          </span>
        </span>
        <FontAwesomeIcon
          icon="caret-down"
          className="transition"
          style={{ transform: `rotate(${isCollapse ? 0 : 180}deg)` }}
          color={isLight ? "black" : "white"}
        />
      </Button>
      {!isCollapse ? (
        children ? (
          children
        ) : items[0] ? (
          <div className="absolute pt-2 w-full z-[1]">
            <div
              className={
                "rounded-lg w-full border-gray-500 border " +
                (isLight ? "bg-white" : "bg-slate-700")
              }
            >
              {items.map((item, i) => (
                <div
                  key={i}
                  className={
                    "border-gray-500 py-1 font-semibold px-4 border-b last:border-b-0 cursor-pointer first:rounded-t-lg last:rounded-b-lg transition " +
                    (isLight
                      ? "hover:bg-gray-100"
                      : "hover:bg-[#415166] text-white")
                  }
                >
                  {item.icon ? (
                    <FontAwesomeIcon icon={item.icon} className="mr-3 w-4" />
                  ) : undefined}
                  {item.label}
                </div>
              ))}
            </div>
          </div>
        ) : undefined
      ) : undefined}
    </div>
  );
}
