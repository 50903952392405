import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef } from "react";
import { useState } from "react";
import Button from "./Button";

export default function Select({
  options = [],
  onChange = () => {},
  style = {},
  outStyle = {},
  outClass = "w-fit",
  className = "",
  value,
  OptionComponent,
  required = false,
  disabled = false
}) {
  const [collapse, setCollapse] = useState(true);
  const isEmpty = !options[0];
  const timeout = useRef();

  const collapseTimer = () => {
    if (!collapse) {
      timeout.current = setTimeout(() => {
        setCollapse(true);
      }, 100);
    }
  };

  const changeValue = (option) => {
    setCollapse(true);
    onChange(option.value);
  };

  const selected = options.find((x) => x.value === value);

  return (
    <div
      className={"relative rounded-lg " + outClass}
      style={outStyle}
      onMouseLeave={collapseTimer}
      onMouseEnter={() => !collapse && clearTimeout(timeout.current)}
    >
      <Button
        className={
          "flex items-center justify-between min-w-[140px] " + className
        }
        disabled={disabled}
        style={style}
        onClick={() => !isEmpty && !disabled && setCollapse(!collapse)}
      >
        <div className="no-select">
          {isEmpty ? (
            <span className="text-gray-500">Select</span>
          ) : selected ? (
            selected.label
          ) : (
            <span className="text-gray-500">Select</span>
          )}
        </div>
        <FontAwesomeIcon
          className="transition ml-2"
          icon="chevron-down"
          style={{ transform: `rotate(${collapse ? 0 : 180}deg)` }}
        />
      </Button>
      {(required && !selected) || isEmpty ? (
        <input
          required
          className="absolute top-0 left-0 w-full py-1 focus-visible:outline-none z-[-1]"
        />
      ) : undefined}
      <div
        className="absolute z-[1] transition-all overflow-hidden w-full"
        style={{
          height: collapse
            ? "0px"
            : 29 * (options.length > 4 ? 4 : options.length) + 5 + "px",
        }}
      >
        <div
          className="bg-white border border-gray-400 rounded-lg transition rounded-lg overflow-y-scroll scrollbar mt-1"
          style={{
            transform: `translateY(${collapse ? "-100%" : "0"})`,
            height: 29 * (options.length > 4 ? 4 : options.length) + "px",
          }}
        >
          {OptionComponent
            ? options.map((option) => (
                <OptionComponent
                  key={option.value}
                  data={option}
                  onClick={() => changeValue(option)}
                />
              ))
            : options.map((option) => (
                <div
                  className="py-1 px-2 border-b last:border-b-0 border-gray-400 no-select cursor-pointer last:rounded-b-lg first:rounded-t-lg hover:bg-gray-100 text-sm"
                  key={option.value}
                  onClick={() => changeValue(option)}
                >
                  {option.label}
                </div>
              ))}
        </div>
      </div>
    </div>
  );
}
