import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Button from "../../components/Button";
import { formatCur } from "../../utils";
import SearchSelect from "../../components/SearchSelect";
import Select from "../../components/Select";
import TextInput from "../../components/TextInput";

const ImportErrorForm = forwardRef(
  ({ data = { pakets: [], daerahs: [] }, submitForm }, ref) => {
    const [actions, setAction] = useState({ paket: [], daerah: [] });
    const [focus, setFocus] = useState(0);

    useEffect(() => {
      setAction({
        paket: new Array(data.pakets.length).fill({
          choice: true,
          value: { bandwidth: "", harga: "" },
        }),
        daerah: new Array(data.daerahs.length).fill({
          choice: true,
        }),
      });
    }, []);

    useImperativeHandle(ref, () => ({
      checkForm() {
        for (let i = 0; i < actions.paket.length; i++) {
          const action = actions.paket[i];
          if (action.choice) {
            if (!action.value.bandwidth && !action.value.harga) {
              setFocus(i);
              break;
            }
          } else {
            if (!action.value.id) {
              setFocus(i);
              break;
            }
          }
        }
      },
    }));

    const daerahPaket = data.daerahs[0] && data.pakets[0];

    const submit = (e) => {
      e.preventDefault();
      const payload = {
        paket: actions.paket.map((action, idx) =>
          action.choice
            ? {
                action: action.choice,
                changeFrom: null,
                changeTo: null,
                newPaket: {
                  harga: action.value.harga,
                  nama: data.pakets[idx],
                  bandwidth: action.value.bandwidth,
                },
              }
            : {
                action: action.choice,
                changeFrom: data.pakets[idx],
                changeTo: action.value.value,
                newPaket: null,
              }
        ),
        daerah: actions.daerah.map((action, idx) =>
          action.choice
            ? {
                action: action.choice,
                changeFrom: null,
                changeTo: null,
                newPaket: data.daerahs[idx],
              }
            : {
                action: action.choice,
                changeFrom: data.daerahs[idx],
                changeTo: action.value.value,
                newPaket: null,
              }
        ),
      };
      submitForm(payload);
    };

    return (
      <form className="flex" id="importErrorForm" onSubmit={submit}>
        {data.pakets[0] ? (
          <div className={daerahPaket ? "w-1/2 pr-2" : "w-full"}>
            {actions.paket.map((curAction, idx) => (
              <div key={idx}>
                <div className="flex justify-between items-center my-1">
                  <div>
                    <div className="leading-3">{data.pakets[idx]}</div>
                    {curAction.choice ? (
                      <div className="text-xs font-semibold">
                        {curAction.value.bandwidth +
                          ", " +
                          formatCur(curAction.value.harga)}
                      </div>
                    ) : undefined}
                  </div>
                  <div className="flex">
                    <Select
                      options={[
                        { value: true, label: "create" },
                        { value: false, label: "change" },
                      ]}
                      value={curAction.choice}
                      onChange={(newVal) =>
                        setAction({
                          ...actions,
                          paket: actions.paket.map((action, i) =>
                            idx === i
                              ? newVal
                                ? {
                                    choice: newVal,
                                    value: { bandwidth: "", harga: "" },
                                  }
                                : {
                                    choice: newVal,
                                    value: { value: "", id: "" },
                                  }
                              : action
                          ),
                        })
                      }
                    />
                    {curAction.choice ? (
                      <Button
                        onClick={() => setFocus(focus === idx ? false : idx)}
                      >
                        <FontAwesomeIcon icon="pen-to-square" />
                      </Button>
                    ) : (
                      <SearchSelect
                        onChange={(x) =>
                          setAction({
                            ...actions,
                            paket: actions.paket.map((action, i) =>
                              idx === i
                                ? {
                                    ...action,
                                    value: { value: x.value, id: x.id },
                                  }
                                : action
                            ),
                          })
                        }
                        placeholder="Paket"
                        className="w-20"
                        request="paket-select-search"
                        value={curAction.value.value}
                        onFocus={() => setFocus(idx)}
                        onSearchChange={(x) =>
                          setAction({
                            ...actions,
                            paket: actions.paket.map((action, i) =>
                              idx === i
                                ? {
                                    ...action,
                                    value: { ...action.value, value: x },
                                  }
                                : action
                            ),
                          })
                        }
                        required
                      />
                    )}
                  </div>
                </div>
                {curAction.choice ? (
                  <div
                    className={
                      "flex overflow-hidden transition-all " +
                      (focus === idx ? "h-[57.6px]" : "h-0")
                    }
                  >
                    <div className="w-1/2 px-2">
                      <label className="text-sm font-medium">Bandwidth</label>
                      <TextInput
                        className="w-full"
                        required
                        value={curAction.value.bandwidth}
                        onChange={(e) =>
                          setAction({
                            ...actions,
                            paket: actions.paket.map((action, i) =>
                              idx === i
                                ? {
                                    ...action,
                                    value: {
                                      ...action.value,
                                      bandwidth: e.target.value,
                                    },
                                  }
                                : action
                            ),
                          })
                        }
                      />
                    </div>
                    <div className="w-1/2 px-2">
                      <label className="text-sm font-medium">Harga</label>
                      <TextInput
                        type="number-comma"
                        className="w-full"
                        required
                        value={curAction.value.harga}
                        onChange={(e) =>
                          setAction({
                            ...actions,
                            paket: actions.paket.map((action, i) =>
                              idx === i
                                ? {
                                    ...action,
                                    value: {
                                      ...action.value,
                                      harga: e.target.value,
                                    },
                                  }
                                : action
                            ),
                          })
                        }
                      />
                    </div>
                  </div>
                ) : undefined}
              </div>
            ))}
          </div>
        ) : undefined}
        {data.daerahs[0] ? (
          <div className={daerahPaket ? "w-1/2 pl-2 " : "w-full"}>
            {actions.daerah.map((curAction, idx) => (
              <div key={idx}>
                <div className="flex justify-between items-center my-1">
                  <div>{data.daerahs[idx]}</div>
                  <div className="flex">
                    <Select
                      options={[
                        { value: true, label: "create" },
                        { value: false, label: "change" },
                      ]}
                      value={curAction.choice}
                      onChange={(newVal) =>
                        setAction(
                          actions.map((action, i) =>
                            idx === i
                              ? newVal
                                ? {
                                    choice: newVal,
                                  }
                                : {
                                    choice: newVal,
                                    value: { value: "", id: "" },
                                  }
                              : action
                          )
                        )
                      }
                    />
                    {curAction.choice ? undefined : (
                      <SearchSelect
                        onChange={(x) =>
                          setAction({
                            ...actions,
                            daerah: actions.daerah.map((action, i) =>
                              idx === i
                                ? {
                                    ...action,
                                    value: { value: x.value, id: x.id },
                                  }
                                : action
                            ),
                          })
                        }
                        placeholder="Daerah"
                        className="w-20"
                        request="daerah-select-search"
                        value={curAction.value.value}
                        onFocus={() => setFocus(idx)}
                        onSearchChange={(x) =>
                          setAction({
                            ...actions,
                            daerah: actions.daerah.map((action, i) =>
                              idx === i
                                ? {
                                    ...action,
                                    value: { ...action.value, value: x },
                                  }
                                : action
                            ),
                          })
                        }
                        required
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : undefined}
      </form>
    );
  }
);

export default ImportErrorForm;
