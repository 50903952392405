import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import Button from "../../components/Button";
import {
  expirationHandler,
  formatCur,
  formatDate,
  formatTimeDate,
  instance,
  parseJwt,
  setToken,
} from "../../utils";

import PrintPage from "./PrintPage";

export default function Example() {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const pembayaranId = useParams().id;
  const [searchParams] = useSearchParams();
  const [pembayaran, setPembayaran] = useState({
    tanggal: "",
    kasir: "",
    nama: "",
    id: "",
    jatuhTempo: "",
    paket: "",
    total: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const token = searchParams.get("token");
    const userData = parseJwt(token);
    dispatch({ type: "SET_USER", payload: userData });
    setToken(token, userData.role);
    instance
      .get("find-pembayaran/" + pembayaranId)
      .then(({ data }) => {
        setPembayaran({
          tanggal: formatTimeDate(data.tanggalBayar),
          kasir: data.pembayar,
          nama: data.costumer,
          id: data.id,
          jatuhTempo: formatDate(data.aktifHingga),
          paket: data.paket,
          hargaPaket: formatCur(data.hargaPaket),
          total: "",
        });
      })
      .catch(expirationHandler);
  }, []);

  return (
    <div className="flex justify-center">
      <div>
        <div className="flex justify-center my-4">
          <Button onClick={handlePrint}>
            <FontAwesomeIcon icon="print" className="w-4" />
          </Button>
          <Button onClick={() => window.close()}>
            <FontAwesomeIcon icon="xmark" className="w-4" />
          </Button>
        </div>
        <PrintPage data={pembayaran} ref={componentRef} />
      </div>
    </div>
  );
}
