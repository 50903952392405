import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";

export default function Navbar({ collapse }) {
  const [collapsed, setCollapse] = collapse;
  const dispatch = useDispatch();
  const [checkbox, setCheckbox] = [
    useSelector((state) => state.checkbox),
    () =>
      dispatch({
        type: "SET_CHECKBOX",
        payload: new Array(checkbox.length).fill(false),
      }),
  ];

  const selected = checkbox.filter((x) => x);

  return (
    <div className="h-10 bg-slate-800 w-screen px-4 flex items-center">
      {selected.length > 0 ? (
        <div className="flex justify-between items-center w-full">
          <button
            onClick={() => setCheckbox(new Array(checkbox.length).fill(false))}
            className="hover:bg-[#263247] p-1 rounded"
          >
            <FontAwesomeIcon size="xl" icon="xmark" color="white" />
          </button>
          <div className="font-semibold text-white text-lg">
            {selected.length} item{selected.length > 1 ? "s" : ""} selected
          </div>
        </div>
      ) : (
        <button
          onClick={() => setCollapse(!collapsed)}
          className="hover:bg-[#263247] p-1 rounded"
        >
          <FontAwesomeIcon size="xl" icon="list" color="white" />
        </button>
      )}
    </div>
  );
}
