export default function Table({
  header= [], row= false
}) {
  return (
    <div className="rounded-t-3xl border-slate-400 border bg-slate-300 overflow-hidden">
      <table className="border-collapse table-auto w-full sm:text-sm text-xs">
        <thead>
          <tr>
            {header.map((x, idx) => (
              <th
                key={idx}
                className="font-medium pb-3 text-center pt-6"
              >
                {x}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{row}</tbody>
      </table>
    </div>
  );
}
