import { useEffect, useState } from "react";
import TextInput from "../../components/TextInput";
import { expirationHandler, instance } from "../../utils";
import Select from "../../components/Select";
import { useSelector } from "react-redux";
import Checkbox from "../../components/Checkbox";

export default function UserModalBody({ userForm, setUserForm, onSubmit }) {
  const [info, setInfo] = useState(true);
  const [pakets, setPakets] = useState([]);
  const [daerahs, setDaerahs] = useState([]);
  const userData = useSelector((state) => state.user);

  useEffect(() => {
    instance
      .get("all-daerah-paket")
      .then(({ data }) => {
        setPakets(data.paket.map((x) => ({ value: x.id, label: x.value })));
        setDaerahs(data.daerah.map((x) => ({ value: x.id, label: x.value })));
        if (userData.role === "subadmin") {
          setTimeout(() => {
            setForm(data.daerah[0].id, "daerah");
          }, 500);
        }
      })
      .catch(expirationHandler);
  }, []);

  const submitUserForm = (e) => {
    e.preventDefault();
    const formArr = [
      "nomor",
      "username",
      "ktp",
      "password",
      "nama",
      "paket",
      "namaKtp",
      "biaya",
      "daerah",
      "aktifHingga",
      "alamat",
    ];
    for (let i = 0; i < formArr.length; i++) {
      const field = formArr[i];
      const value = userForm[field];
      console.log(field, field === "biaya" && userForm.id, field === "ktp");
      if ((field === "biaya" && userForm.id) || field === "ktp") continue;
      if (!value) return setInfo(i % 2);
    }
    onSubmit();
  };

  const setForm = (newVal, attr) => {
    const newForm = { ...userForm };
    newForm[attr] = newVal;
    setUserForm(newForm);
  };

  return (
    <form
      className="flex flex-wrap mt-[-20px]"
      id="userForm"
      onSubmit={submitUserForm}
    >
      <div
        className={
          "w-1/2 text-center font-medium pt-1 pb-2 border-t-2 cursor-pointer " +
          (info
            ? "bg-gradient-to-b from-slate-200 via-slate-200 to-transparent border-gray-400"
            : "border-transparent")
        }
        onClick={() => setInfo(true)}
      >
        Layanan
      </div>
      <div
        className={
          "w-1/2 text-center font-medium pt-1 pb-2 border-t-2 cursor-pointer " +
          (!info
            ? "bg-gradient-to-b from-slate-200 via-slate-200 to-transparent border-gray-400"
            : "border-transparent")
        }
        onClick={() => setInfo(false)}
      >
        Info Pelanggan
      </div>
      {!info ? (
        <div className="flex flex-wrap">
          <div className="w-1/2 px-2 py-1">
            <label className="text-sm font-medium">Nama</label>
            <TextInput
              onChange={(e) => setForm(e.target.value, "nama")}
              placeholder="Nama"
              className="w-full"
              value={userForm.nama}
              required
            />
          </div>
          <div className="w-1/2 px-2 py-1">
            <label className="text-sm font-medium">Nama Sesuai KTP</label>
            <TextInput
              value={userForm.namaKtp}
              required
              placeholder="Nama Sesuai KTP"
              onChange={({ target }) => setForm(target.value, "namaKtp")}
              type="namaKtp"
              className="w-full"
            />
          </div>
          <div className="w-1/2 px-2 py-1">
            <label className="text-sm font-medium">Daerah</label>
            <Select
              required
              className="w-full"
              outClass="w-full"
              options={daerahs}
              value={userForm.daerah}
              disabled={userData.role === "subadmin"}
              onChange={(e) => setForm(e, "daerah")}
            />
          </div>
          <div className="w-1/2 px-2 py-1">
            <label className="text-sm font-medium">Alamat</label>
            <TextInput
              value={userForm.alamat}
              required
              onChange={({ target }) => setForm(target.value, "alamat")}
              className="w-full"
              placeholder="alamat"
            />
          </div>
          <div className="w-1/2 px-2 py-1">
            <label className="text-sm font-medium">Nomor</label>
            <TextInput
              value={userForm.nomor}
              required
              type="number"
              onChange={({ target }) => setForm(target.value, "nomor")}
              className="w-full"
              placeholder="nomor"
            />
          </div>
          <div className="w-1/2 px-2 py-1">
            <label className="text-sm font-medium">KTP</label>
            <TextInput
              value={userForm.ktp}
              type="text"
              onChange={({ target }) => setForm(target.value, "ktp")}
              className="w-full"
              placeholder="nomor KTP"
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-wrap justify-center">
          <div className="w-1/2 px-2 py-1">
            <label className="text-sm font-medium">Username</label>
            <TextInput
              value={userForm.username}
              required
              placeholder="username"
              onChange={({ target }) => setForm(target.value, "username")}
              className="w-full"
            />
          </div>
          <div className="w-1/2 px-2 py-1">
            <label className="text-sm font-medium">Password</label>
            <TextInput
              value={userForm.password}
              required
              placeholder="password"
              onChange={({ target }) => setForm(target.value, "password")}
              type="password"
              className="w-full"
            />
          </div>
          <div className="w-1/2 px-2 py-1">
            <label className="text-sm font-medium">Paket</label>
            <Select
              value={userForm.paket}
              options={pakets}
              required
              className="w-full"
              outClass="w-full"
              onChange={(e) => setForm(e, "paket")}
            />
          </div>
          <div className="w-1/2 px-2 py-1">
            <label className="text-sm font-medium">Aktif Hingga</label>
            <input
              type="date"
              className="border border-gray-400 px-3 py-1 rounded-lg w-full"
              required={info}
              onChange={({ target }) => setForm(target.value, "aktifHingga")}
              value={userForm.aktifHingga}
            />
          </div>
          {!userForm.id && (
            <div className="w-1/2 px-2 py-1">
              <label className="text-sm font-medium">Biaya</label>
              <input
                type="number"
                className="border border-gray-400 px-3 py-1 rounded-lg w-full"
                required={info}
                placeholder="biaya Instalasi"
                step="10000"
                onChange={({ target }) => setForm(target.value, "biaya")}
                value={userForm.biaya}
              />
            </div>
          )}
          <div
            className={
              (!userForm.id ? "w-1/2 " : "") + "px-2 py-1 flex items-center"
            }
          >
            <label>
              <Checkbox
                value={userForm.notify}
                onChange={({ target }) => setForm(target.checked, "notify")}
              />
              <span className="text-sm font-medium pl-3">Notify</span>
            </label>
          </div>
        </div>
      )}
    </form>
  );
}
