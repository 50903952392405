import { useSelector } from "react-redux";
import Button from "./Button";

export default function Paging({ movePage, page, dataLength }) {
  const isMobile = useSelector((state) => state.isMobile);
  const size = isMobile ? 3 : 5;
  const moreCenter = Math.ceil(size / 2);
  const lessCenter = size - moreCenter;
  const changeOrder = page < Math.floor(size / 2) + 3 || dataLength < size;

  return (
    <div className="flex justify-between mt-5 sm:text-sm">
      <Button disabled={page <= 1} onClick={() => movePage(page - 1)}>
        prev
      </Button>
      <div className="flex">
        <Button
          variant={page === 1 ? "dark" : "light"}
          onClick={() => movePage(1)}
          className={changeOrder ? "mx-[1px]" : "mr-2"}
        >
          1
        </Button>
        <div>
          {Array.from(
            { length: dataLength > size ? size : dataLength - 1 },
            (_, i) =>
              i +
              (page < Math.floor(size / 2) + 3 || dataLength < size
                ? 2
                : page >= dataLength - moreCenter
                ? dataLength - size
                : page - lessCenter)
          ).map((val) => (
            <Button
              key={val}
              variant={page === val ? "dark" : "light"}
              className="mx-[1px]"
              onClick={() => movePage(val)}
            >
              {val}
            </Button>
          ))}
        </div>
        {dataLength > 6 ? (
          <Button
            variant={page === dataLength ? "dark" : "light"}
            onClick={() => movePage(dataLength)}
            className={page >= dataLength - moreCenter ? "mx-[1px]" : "ml-2"}
          >
            {dataLength}
          </Button>
        ) : undefined}
      </div>
      <Button disabled={dataLength <= page} onClick={() => movePage(page + 1)}>
        next
      </Button>
    </div>
  );
}
