import Button from "../../components/Button";
import TextInput from "../../components/TextInput";

export default function FormTemplate({
  formState,
  inputs,
  id,
  title,
  onSubmit,
  mikrotikForm,
  confirmButton,
}) {
  const [form, setFormValue] = formState;

  const setForm = (attr, value) => {
    const newValue = { ...form };
    newValue[attr] = value;
    if (mikrotikForm) {
      newValue.id = "";
    }
    setFormValue(newValue);
  };

  const toTitleCase = (text) => {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  return (
    <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] rounded-lg shadow-lg w-80 bg-white p-7">
      <div className="text-center text-2xl font-semibold">{title}</div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
        id={id}
        className="flex flex-wrap"
      >
        {inputs.map((input, idx) => (
          <div key={idx} className="w-full px-2 pt-1">
            <label className="text-sm ml-2 font-semibold">
              {input.label ? input.label : toTitleCase(input.attribute)}
            </label>
            <TextInput
              className="w-full"
              value={form[input.attribute]}
              onChange={(e) => setForm(input.attribute, e.target.value)}
              placeholder={toTitleCase(input.attribute)}
              type={input.type || "text"}
              pattern={
                input.ip
                  ? "^((\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])\\.){3}(\\d{1,2}|1\\d\\d|2[0-4]\\d|25[0-5])$"
                  : null
              }
              required
            />
          </div>
        ))}
        <Button
          type="submit"
          variant="dark"
          className="w-full mt-4 font-semibold"
        >
          {confirmButton}
        </Button>
      </form>
    </div>
  );
}
