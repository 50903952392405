import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import { baseURL, errorHandler, parseJwt, setToken } from "../../utils";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // sessionStorage.removeItem("token");
    // axios
    //   .get(baseURL + "guest/isready", {
    //     crossDomain: true,
    //   })
    //   .then(({ data }) => {
    //     console.log(data.ready, typeof data.ready);
    //     if (!data.ready) {
    //       return navigate("/setup");
    //     }
    //     localStorage.setItem("ready", "true");
    //   })
    //   .catch((e) => {
    //     errorHandler(e);
    //   });
  }, []);

  const logging = (e) => {
    e.preventDefault();
    axios
      .post(baseURL + "guest/login", {
        username,
        password,
      })
      .then(({ data }) => {
        const tokenData = parseJwt(data.token);
        sessionStorage.setItem("token", data.token);
        dispatch({ type: "SET_USER", payload: tokenData });
        navigate("/costumer");
        setToken(data.token, tokenData.role);
      })
      .catch(errorHandler);
  };

  return (
    <div className="h-full">
      <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-80%] rounded-lg shadow-lg bg-white sm:w-96 w-full">
        <div className="text-4xl text-center leading-[4rem] font-bold">
          Login
        </div>
        <form onSubmit={logging} className="my-4 px-10">
          <TextInput
            className="w-full my-2"
            value={username}
            onChange={({ target }) => setUsername(target.value)}
            placeholder="Username"
            required
          />
          <TextInput
            className="w-full my-2"
            value={password}
            onChange={({ target }) => setPassword(target.value)}
            placeholder="Password"
            type="password"
            required
          />
          <div className="px-1">
            <Button
              variant="dark"
              type="submit"
              className="w-full mx-0 font-bold mt-4"
            >
              Sign In
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
