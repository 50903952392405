import { formatDate, preventLongPress, useLongPress } from "../../utils";

export default function CostumerRow({
  data,
  methods,
  checkboxState,
  selected,
  isAdmin
}) {
  const { setFormToRow, setModalType, toggleStatus } = methods;
  const [checkbox, setCheckbox] = checkboxState;
  const columns = [
    data.nama,
    <div className="w-full flex justify-center">
      <div className={"absolute right-0 top-0 w-1/6 h-full " + (data.connected?"bg-sky-200":"bg-rose-200" ) }></div>
      <div
        { ...preventLongPress}
        className={"py-1 px-3 w-fit rounded-md border border-white cursor-pointer " + (data.active ? (data.lunas ? "text-sky-600 bg-sky-200" : "text-lime-600 bg-lime-200") : (data.lunas ? "text-red-600 bg-red-200" : "text-amber-600 bg-amber-200"))}
        onClick={() => isAdmin && toggleStatus(data, !data.active)}
      >
        {formatDate(data.aktifHingga)},
      </div>
    </div>,
  ];

  const onClick = () => {
    if (selected) return setCheckbox(!checkbox);
    setFormToRow(data);
    setModalType("mobileDetail");
  };

  const onHold = () => {
    if (selected) return;
    setCheckbox(true);
    navigator.vibrate(200);
  };

  const longPressEvent = useLongPress(onHold, onClick);

  return (
    <tr
      {...longPressEvent}
      className={
        "border-slate-400 even:bg-slate-300 odd:bg-[#bdcada] last:border-b-0 border-b border-t transition hover:bg-[#a7b9cd]" +
        (selected ? (checkbox ? "" : " opacity-70") : "")
      }
    >
      {columns.map((x, idx) => (
        <td
          key={idx}
          className={"items-center font-medium pb-2 text-center pt-2" + (idx === 1 ? " relative" : "")}
        >
          {x}
        </td>
      ))}
    </tr>
  );
}
