import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Button from "../../components/Button";
import QrCode from "../../components/QrCode";
import { io } from "socket.io-client";
import {
  baseURL,
  expirationHandler,
  formatCur,
  formatDate,
  instance,
} from "../../utils";
import waBg from "./whatsappBg.png";
import Loading from "../../components/Loading";
import TextInput from "../../components/TextInput";
import Swal from "sweetalert2";
import Select from "../../components/Select";

const OnlineForm = ({ whatsappForm, setIsLoading }) => {
  const [form, setForm] = whatsappForm;
  const [displayMsg, setDisplayMsg] = useState(1);
  const costumerPlaceholder = {
    id: 232,
    nama: "Name Placeholder",
    namaKtp: "Sesuai KTP",
    username: "username_placeholder",
    alamat: "Alamat Pelanggan",
    tanggal_aktif: new Date(),
    nomor: "nomor",
    Paket: {
      id: 3,
      groupname: "Nama Paket",
      price: formatCur(125000),
    },
    Daerah: {
      id: 1,
      name: "Daerah",
    },
    Pembayaran: {
      amount: formatCur(200000),
      total: formatCur(200000 + 125000),
    },
  };

  const getCurrentMessage = () => {
    switch (displayMsg) {
      case 1:
        return "warn";
      case 2:
        return "perpanjang";
      case 3:
        return "send";
      case 4:
        return "installasi";
      default:
        return undefined;
    }
  };

  const currentMessage = getCurrentMessage();

  const processMessage = () =>
    form.messages[currentMessage]
      .split("||")
      .map((x) => {
        if (x.length > 17 || x === ".") return x;
        const attributes = x.trim().split(".");
        let val = { ...costumerPlaceholder };
        for (let i = 0; i < attributes.length; i++) {
          val = val[attributes[i]];
          if (!val) return x;
        }
        if (!val || val.id === costumerPlaceholder.id) return x;
        if (val instanceof Date) return formatDate(val);
        return val;
      })
      .join("");

  const editWhatsapp = (e) => {
    e.preventDefault();
    setIsLoading(true);
    instance
      .put("edit-whatsapp", form)
      .then(() => {
        Swal.fire({
          title: "Berhasil",
          icon: "success",
          text: "Perubahan telah disimpan",
          timer: 2000,
        });
        setIsLoading(false);
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  return (
    <div className="w-full py-4 flex">
      <form onSubmit={editWhatsapp} className="w-full px-4 relative">
        <div className="flex items-center">
          <div className="flex mr-2">Pesan:</div>
          <Select
            options={[
              {
                label: "Peringatan",
                value: 1,
              },
              {
                label: "Perpanjang",
                value: 2,
              },
              {
                label: "Kirim Pesan",
                value: 3,
              },
              {
                label: "Installasi",
                value: 4,
              },
            ]}
            value={displayMsg}
            onChange={(val) => {
              setDisplayMsg(val);
            }}
          />
        </div>
        {displayMsg === 1 ? (
          <div className="flex">
            <div className="w-full">
              <div>Waktu Pengiriman</div>
              <input
                value={form.time}
                onChange={(e) => setForm("time", e.target.value)}
                type="time"
                className="border border-gray-400 px-3 py-0.5 rounded-lg"
              />
            </div>
            <div className="w-full">
              <div>Hari Sebelum</div>
              <TextInput
                value={form.daysBefore}
                onChange={(e) => setForm("daysBefore", e.target.value)}
                type="number"
                className="w-16"
              />
            </div>
          </div>
        ) : undefined}
        <div>Pesan yang dikirm</div>
        <textarea
          value={form.messages[currentMessage]}
          onChange={(e) =>
            setForm("messages", {
              ...form.messages,
              [currentMessage]: e.target.value,
            })
          }
          className="w-full mt-2 border border-gray-400 px-3 py-1 rounded-lg"
          rows={4}
        />
        {/* Preview */}
        <div className="w-[360px] p-6 pl-3 bg-[#111b21] relative overflow-hidden rounded-lg mt-2">
          <img src={waBg} className="opacity-10 absolute top-0 left-0 z-[0]" />
          <div className="z-[1] relative text-white text-sm pr-6 flex">
            <div
              className="w-3 h-4 bg-[#202c33]"
              style={{ clipPath: "polygon(100% 100%, 0 0, 100% 0)" }}
            ></div>
            <div className="flex-1">
              <div
                className="w-fit bg-[#202c33] rounded-r-lg rounded-b-lg whitespace-pre-wrap"
                style={{
                  paddingLeft: 9,
                  paddingTop: 6,
                  paddingRight: 9,
                  paddingBottom: 8,
                }}
              >
                {processMessage()}
              </div>
            </div>
          </div>
        </div>
        <Button
          type="submit"
          variant="dark"
          className="absolute bottom-3 right-3"
        >
          Save Changes
        </Button>
      </form>
      <div className="w-full px-4">
        <div className="bg-slate-700 text-white rounded-lg px-4 py-2 w-full">
          <code className="whitespace-pre-wrap text-sm">
            {JSON.stringify(costumerPlaceholder, null, 2).replace(
              /"([^"]+)":/g,
              "$1:"
            )}
          </code>
        </div>
      </div>
    </div>
  );
};

const Whatsapp = forwardRef(({ whatsapp, setIsLoading }, ref) => {
  const [token, setToken] = useState("");
  const [socket, setSocket] = useState(null);
  const status = whatsapp[0].status;
  const setStatus = (newVal) => whatsapp[1]("status", newVal);

  const openConnection = () => {
    let tempSocket = io("ws://" + baseURL.split("//")[1]);
    setSocket(tempSocket);
    let isLoading = true;
    tempSocket.on("token", (args) => {
      if (isLoading) {
        setStatus("open");
        isLoading = false;
      }
      setToken(args);
    });
    setStatus("loading");
    tempSocket.on("finished", () => {
      setStatus("online");
      tempSocket.disconnect();
    });
    tempSocket.on("failed", () => {
      tempSocket.disconnect();
      setStatus("offline");
    });
  };

  const cancelConnection = () => {
    socket.disconnect();
    setStatus("offline");
  };

  useImperativeHandle(ref, () => ({
    openConnection,
  }));

  const checkStatus = () => {
    switch (status) {
      case "offline":
        return (
          <div className="py-4">
            <Button variant="dark" onClick={openConnection}>
              Connect Whatsapp
            </Button>
          </div>
        );
      case "online":
        return (
          <OnlineForm whatsappForm={whatsapp} setIsLoading={setIsLoading} />
        );
      case "loading":
        return (
          <div className="py-4">
            <div
              className="absolute right-3 top-3 rounded-lg bg-slate-300 leading-none p-0.5 cursor-pointer"
              onClick={cancelConnection}
            >
              <FontAwesomeIcon icon="xmark" className="w-6 h-6" />
            </div>
            <div className="overflow-hidden rounded-lg shadow-lg p-4 bg-white my-5">
              <div className="h-[300px] w-[300px] flex items-center justify-center">
                <Loading />
              </div>
            </div>
          </div>
        );
      case "open":
        return (
          <div className="py-4">
            <div
              className="absolute right-3 top-3 rounded-lg bg-slate-300 leading-none p-0.5 cursor-pointer"
              onClick={cancelConnection}
            >
              <FontAwesomeIcon icon="xmark" className="w-6 h-6" />
            </div>
            <div className="overflow-hidden rounded-lg shadow-lg">
              <QrCode data={token} />
            </div>
          </div>
        );
    }
  };

  return (
    <div>
      <div className="px-3 py-1 bg-slate-800 text-xl text-white font-semibold">
        Whatsapp
      </div>
      <div className={"flex justify-center items-center z-[0] relative"}>
        {checkStatus()}
      </div>
    </div>
  );
});

export default Whatsapp;
