import { useEffect, useState } from "react";
import Select from "../../components/Select";
import { instance, serialize } from "../../utils";

export default function DataChange({ isPaket, changeState, remove }) {
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = changeState;

  useEffect(() => {
    instance
      .get("all-" + (isPaket ? "paket" : "daerah") + serialize({ remove }))
      .then(({ data }) => {
        setOptions(data.map((x) => ({ label: x.value, value: x.id })));
      });
  }, []);

  return (
    <div className="mt-3 flex items-center">
      <div className="mr-3">ganti ke {isPaket ? "paket" : "daerah"}</div>
      <Select
        options={options}
        onChange={(x) => setSelected(x)}
        value={selected}
      />
    </div>
  );
}
