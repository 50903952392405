import { forwardRef } from "react";

const PrintPage = forwardRef(({ data }, ref) => {
  return (
    <div ref={ref} className="w-[21rem] bg-white py-8 px-4 font-semibold">
      <div className="text-center">
        <div className="font-bold">NESTA INDO MEDIA</div>
        <div>
          Jl. Gatot Subroto 43 Bandarjo Ungaran Barat
        </div>
      </div>
      <div className="mt-8">
        <div className="flex justify-between border-b">
          <div>Tanggal</div>
          <div>{data.tanggal}</div>
        </div>
        <div className="flex justify-between border-b">
          <div>Kasir</div>
          <div>{data.kasir}</div>
        </div>
        <div className="flex justify-between border-b">
          <div>Nama</div>
          <div>{data.nama}</div>
        </div>
        <div className="flex justify-between border-b">
          <div>ID Pelanggan</div>
          <div>{data.id}</div>
        </div>
        <div className="flex justify-between border-b">
          <div>Jatuh Tempo</div>
          <div>{data.jatuhTempo}</div>
        </div>
      </div>
      <div className="mt-4">
        <div className="flex justify-between border-b font-bold">
          <div>{data.paket}</div>
          <div>{data.hargaPaket}</div>
        </div>
        <div className="border-b italic">
          <div>Biaya Langganan</div>
          <div>Internet</div>
        </div>
        <div className="flex justify-between border-b">
          <div>Sewa Perangkat</div>
          <div>Rp. 0</div>
        </div>
        <div className="flex justify-between mt-4 border-b">
          <div>Total:</div>
          <div>{data.hargaPaket}</div>
        </div>
      </div>
      <div className="mt-6 italic font-normal text-center">
        Pembayaran sudah diterima, terima kasih sudah melunasi tagihan anda !
      </div>
    </div>
  );
});

export default PrintPage;
