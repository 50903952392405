import { forwardRef, useEffect, useRef, useState } from "react";
import TextInput from "./TextInput";
import { instance, serialize } from "../utils";

const SearchSelect = forwardRef(
  (
    {
      onChange,
      remove = null,
      request,
      placeholder = "",
      required,
      className = "",
      value = "",
      onSearchChange = () => {},
      onFocus = () => {},
    },
    ref
  ) => {
    const [options, setOptions] = useState([]);
    const [status, setStatus] = useState(null);
    const [focus, setFocus] = useState(false);
    const localRef = useRef(null);
    const inputRef = ref || localRef;

    const found = options.find((x) => x.value === value);

    useEffect(() => {
      if (!found) {
        setStatus("searching");
        const timeOutId = setTimeout(() => {
          const params = { query: value };
          if (remove) params.remove = remove;
          instance
            .get(request + serialize(params))
            .then(({ data }) => {
              setOptions(data);
              if (data[0]) {
                const isFound = data.find((x) => x.value === value);
                if (isFound) {
                  setStatus("found");
                  onChange(isFound);
                  return;
                }
                setStatus("show");
                return;
              }
              setStatus("empty");
            })
            .catch((e) => console.error(e));
        }, 500);
        return () => clearTimeout(timeOutId);
      } else {
        setStatus("found");
      }
    }, [value]);

    const result = () => {
      switch (status) {
        case "show":
          return (
            <div className="absolute w-full bg-white rounded-lg mt-1 border border-gray-400 z-[1]">
              {options.map((option) => (
                <div
                  key={option.id}
                  className="px-2 py-1 border-b border-gray-400 hover:bg-gray-200 cursor-pointer no-select last:rounded-b-lg first:rounded-t-lg last:border-0"
                  onClick={() => {
                    onSearchChange(option.value);
                    onChange(option);
                    setStatus("found");
                  }}
                >
                  {option.value}
                </div>
              ))}
            </div>
          );
        case "searching":
          return (
            <div className="absolute w-full bg-white rounded-lg mt-1 border border-gray-400 text-gray-500 px-2 py-1 z-[1]">
              Searching..
            </div>
          );
        case "empty":
          return (
            <div className="absolute w-full bg-white rounded-lg mt-1 border border-gray-400 text-gray-500 px-2 py-1 z-[1]">
              Empty Data
            </div>
          );
        default:
          return undefined;
      }
    };

    return (
      <div className="relative w-fit">
        <TextInput
          value={value}
          className={className ? className : "w-32"}
          onChange={(e) => {
            const isFound = options.find((x) => x.value === e.target.value);
            if (found && !isFound) {
              onChange({ value: e.target.value, id: null });
            } else if (isFound) {
              onChange(isFound);
              setStatus("found");
            } else {
              onSearchChange(e.target.value);
            }
          }}
          onFocus={() => {
            setFocus(true);
            onFocus();
          }}
          placeholder={placeholder}
          onBlur={() => setTimeout(() => setFocus(false), 250)}
          required={required}
          ref={inputRef}
          pattern={options.map((x) => x.value).join("|")}
        />
        {focus ? result() : undefined}
      </div>
    );
  }
);

export default SearchSelect;
