import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import router from "./router";
import { Provider } from "react-redux";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import store from "./redux/store";
import { RouterProvider } from "react-router-dom";

library.add(fas, far);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);
