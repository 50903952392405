import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "../../components/Button";
import Select from "../../components/Select";
import { expirationHandler, instance } from "../../utils";

export default function FilterModal({ onSubmit, filterState }) {
  const [date, setDate] = useState({
    start: filterState.start ? filterState.start.split('T')[0] : "",
    end: filterState.end ? filterState.end.split('T')[0] : "",
  });
  const [type, setType] = useState(filterState.type);
  const [daerah, setDaerah] = useState(filterState.daerah);
  const [method, setMethod] = useState(filterState.method);
  const [daerahs, setDaerahs] = useState([]);
  const userData = useSelector((state) => state.user);
  const isAdmin = userData && userData.role === "admin";

  useEffect(() => {
    if (isAdmin) {
      instance
        .get("all-daerah")
        .then(({ data }) => {
          setDaerahs(data.map((x) => ({ value: x.value, label: x.value })));
        })
        .catch(expirationHandler);
    }
  }, []);

  const submit = (e) => {
    e.preventDefault();
    const dateEmpty = !date.start || !date.end;
    if (dateEmpty) return;
    let dateToSubmit = {};
    if (!dateEmpty) {
      dateToSubmit.start = new Date(date.start).toISOString();
      dateToSubmit.end = new Date(date.end).toISOString();
    } else {
      dateToSubmit.start = "";
      dateToSubmit.end = "";
    }
    onSubmit({ ...dateToSubmit, daerah, method, type });
  };
  const reset = () => {
    onSubmit({
      start: "",
      end: "",
      daerah: "",
      method: "",
      type: "bayar"
    })
  }

  return (
    <form id="filterForm" onSubmit={submit}>
      <div className="flex flex-wrap justify-center">
        <div className="flex w-full justify-end">
          <span onClick={reset} className="leading-none border-b hover:text-zinc-800 text-zinc-700 cursor-pointer">reset</span>
        </div>
        <div className="px-1 w-1/2">
          <label className="text-sm font-medium">Awal</label>
          <input
            type="date"
            max={
              date.end ? new Date(date.end).toISOString().split("T")[0] : null
            }
            className="border border-gray-400 px-3 py-1 rounded-lg w-full"
            onChange={(e) => setDate({ ...date, start: e.target.value })}
            value={date.start}
          />
        </div>
        <div className="px-1 w-1/2">
          <label className="text-sm font-medium">Akhir</label>
          <input
            type="date"
            min={
              date.start
                ? new Date(date.start).toISOString().split("T")[0]
                : null
            }
            className="border border-gray-400 px-3 py-1 rounded-lg w-full"
            onChange={(e) => setDate({ ...date, end: e.target.value })}
            value={date.end}
          />
        </div>
        <label className="text-sm w-full mt-2 font-medium">Pembayaran</label>
        <div className="px-1 w-1/2">
          <Button
            className="w-full"
            variant={method === "cash" ? "dark" : "light"}
            onClick={() => setMethod(method === "cash" ? "" : "cash")}
          >
            Cash
          </Button>
        </div>
        <div className="w-1/2 px-1 ">
          <Button
            className="w-full"
            variant={method === "transfer" ? "dark" : "light"}
            onClick={() => setMethod(method === "transfer" ? "" : "transfer")}
          >
            Transfer
          </Button>
        </div>
        <div className="w-1/2 px-1">
          <label className="text-sm font-medium">Category</label>
          <Select
            options={[{value: "bayar", label: "Bayar"}, {value: "install", label: "Instalasi"}]}
            value={type}
            outClass="w-full"
            className="w-full"
            onChange={(x) => setType(x)}
          />
        </div>
        {isAdmin && (
          <div className="w-1/2 px-1">
            <label className="text-sm font-medium">Daerah</label>
            <Select
              options={daerahs}
              value={daerah}
              outClass="w-full"
              className="w-full"
              onChange={(x) => setDaerah(x)}
            />
          </div>
        )}
      </div>
    </form>
  );
}
