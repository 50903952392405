import { useEffect, useState } from "react"
import { expirationHandler, formatCur, formatDate, formatTimeDate, instance, serialize, waitForToken } from "../../utils"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Select from "../../components/Select";
import Table from "../../components/Table";
import LoadingScreen from "../../components/LoadingScreen";

export default function Keuangan() {
  const [datas, setDatas] = useState({
    in: [],
    out: [],
    money: {
      in: 0,
      out:0,
      profit: 0
    }
  });
  const now = new Date();
  const [filter, setFilter] = useState(now.getFullYear()+ "-" +(now.getMonth()+1));
  const [type, setType] = useState("month");
  const [initialized, setInitialized] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (initialized) {
      let timeOutId;
      if (type === "dates") {
        if (dates[0] && dates[1]) {
          timeOutId = setTimeout(() => {
            getDashboard();
          }, 750);
        }
      } else {
        timeOutId = setTimeout(() => {
          getDashboard();
        }, 750);
      }
      return () => clearTimeout(timeOutId);
    } else setInitialized(true)
  }, [filter]);

  useEffect(() => {
    waitForToken(getDashboard)
  }, [])

  const getDashboard = () => {
    instance.get("dashboard-keuangan"+
    serialize({type, date: filter})).then(({data}) => {
      setIsLoading(false)
      setDatas({
        in: data.in,
        out: data.out,
        money: {
          in: data.numbers.in,
          out: data.numbers.out,
          profit: data.numbers.in-data.numbers.out
        }
      })
    }).catch((e) => expirationHandler(e, setIsLoading));
  }

  const changeType = (e) => {
    setType(e);
    if (e === "dates") return setFilter("|");
    setFilter("");
  }

  const cards = () => {
    const cardData = [
      {
        title: "Pemasukan",
        jumlah: formatCur(datas.money.in),
        icon: "money-bill-wave"
      },
      {
        title: "Pengeluaran",
        jumlah: formatCur(datas.money.out),
        icon: "money-bill-wave"
      },
      {
        title: "Keuntungan",
        jumlah: formatCur(datas.money.profit),
        icon: "money-bill-wave"
      }
    ];

    return cardData.map((x, i) => (
      <div key={i} className="w-full my-2 px-2">
        <div className="bg-slate-700 text-white text-2xl relative px-4 py-8 font-bold overflow-hidden rounded-xl">
          <div>{x.title}</div>
          <div>{x.jumlah}</div>
          <FontAwesomeIcon
            icon={x.icon}
            className="absolute text-8xl right-[-5px] bottom-[-10px] opacity-25"
          />
        </div>
      </div>
    ))
  }
  const dates = filter.split("|")
  const setDates = (e, left) => {
    if (left) return setFilter(e.target.value+"|"+dates[1])
    setFilter(dates[0]+"|"+e.target.value)
  }
  return (
    <div className="p-4">
      <LoadingScreen show={isLoading} />
      <div className="flex">
        {cards()}
      </div>
      <form className="flex px-2 justify-between">
        <Select 
          options={[
            {label: "Tahun", value: "year"},
            {label: "Bulan", value: "month"},
            {label: "Tanggal", value: "dates"}
          ]}
          value={type}
          onChange={changeType}
          />
        {type === "dates" ?
        <div>
          <input type="date" className="border block border-gray-400 mb-2 px-3 py-1 rounded-lg" onChange={(e) => setDates(e, true)} value={dates[0]} />
          <input type="date" className="border block border-gray-400 px-3 py-1 rounded-lg" onChange={setDates} value={dates[1]} />
        </div> 
        :
        <input type={type==="month"?"month":"number"} className="border border-gray-400 px-3 py-1 rounded-lg" onChange={(e) => setFilter(e.target.value)} value={filter} />
        }
      </form>
      <div>
        <div className="mb-4 mt-6 text-xl">Pemasukan</div>
        <Table header={["Nama", "Jumlah", "Pembayaran", "Tanggal Bayar", "Pembayar"]} row={ datas.in.map((x) => {
          const cols = [
            x.costumer,
            formatCur(x.hargaPaket),
            x.method,
            formatTimeDate(x.tanggalBayar),
            x.pembayar,
          ];
          return (
            <tr
              key={x.id}
              className="border-slate-400 last:border-b-0 border-b border-t transition hover:bg-[#a7b9cd] odd:bg-[#bdcada] "
            >
              {cols.map((val, idx) => (
                <td
                  key={idx}
                  className="items-center font-medium pb-2 text-center pt-2"
                >
                  {val}
                </td>
              ))}
            </tr>
          );
        })} />
        <div className="mb-4 mt-6 text-xl">Pengeluaran</div>
        <Table header={["Nama", "Jumlah", "Tanggal"]} row={datas.out.map((x) => {
          const cols = [x.name, formatCur(x.amount), formatDate(x.tanggal)];
          return (
            <tr
              key={x.id}
              className="border-slate-400 last:border-b-0 border-b border-t transition hover:bg-[#a7b9cd] odd:bg-[#bdcada] "
            >
              {cols.map((val, idx) => (
                <td
                  key={idx}
                  className="items-center font-medium pb-2 text-center pt-2"
                >
                  {val}
                </td>
              ))}
            </tr>
          );
        })} />
      </div>
    </div>
  )
}